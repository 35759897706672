import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/friday-the-13th-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1980 slasher movie, Friday the 13th"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 21</h1>
                    <h2>Friday the 13th</h2>
                    <h3>October 13, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/friday-the-13th">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:02.372)
You&#39;re listening to Bring Me the Axe. I&#39;m Brian White, one half of this Morbid Equation, and I&#39;m joined by my co-host and actual brother Dave White. Dave, how you doing?</p>
<p>Dave! (00:10.542)
Well, I&#39;m alright. I just accidentally opened a bottle of wine and I did not want wine. I didn&#39;t think it was going to be wine, and... ..ah, that&#39;s where I find myself.</p>
<p>Bryan! (00:19.428)
Yeah, there&#39;s like, you know, not much of a drinker these days, as you know, but there&#39;s nothing there&#39;s nothing worse than surprise wine, because I don&#39;t I don&#39;t get it never got the appeal of wine. What is it&#39;s all toilet wine to me.</p>
<p>Dave! (00:29.422)
Surprise wine, toilet wine.</p>
<p>Dave! (00:34.89)
That&#39;s all toilet wine from here on out.</p>
<p>Bryan! (00:37.06)
Yeah. Hey, so what are we doing here on a Friday? We never we don&#39;t release on Fridays.</p>
<p>Dave! (00:42.079)
It is a... It is a Friday... It&#39;s a very special Friday. On this very special episode of Very Special Friday.</p>
<p>Bryan! (00:48.164)
Yep. Yeah, lesson. Yeah. Yeah, very space. No, no.</p>
<p>Dave! (00:50.914)
Val Kilmer has a drinking problem. Remember that movie? Remember that TV, made for TV movie? Where Val Kilmer, it&#39;s Val Kilmer and Mer Winningham and somebody else was in it too. And Val Kilmer was an alcoholic, a teenage alcoholic. And the only reason I remember this movie is because we watched it in health class and I got kicked out because Val Kilmer gets drunk and accidentally hits someone with his car and I audibly laughed when it happened. And I&#39;m sure I was being an asshole throughout the entire class, but...</p>
<p>Bryan! (01:07.094)
Ah!</p>
<p>Dave! (01:20.187)
I just remember that movie because I got kicked out of health class in high school for laughing at what is a very serious problem, I guess.</p>
<p>Bryan! (01:23.332)
That&#39;s very, very funny. I know I&#39;ve never I&#39;ve never heard of this of this thing. I was going to ask if it was one of Sam Pancakes shows because it kind of sounds like it&#39;s one it&#39;s right up his alley. Yeah, it would have to it would have to be because he really found stardom pretty quickly. Oh, God.</p>
<p>Dave! (01:36.782)
It was like a mid 80s, early 80s.</p>
<p>Bryan! (01:45.176)
Yeah, yeah. So it is a it is Friday the 13th in October of all things and you didn&#39;t think we&#39;re gonna sleep on this. Of course, we&#39;re gonna do a Friday the 13th episode.</p>
<p>Dave! (01:55.362)
Yeah, we&#39;re gonna be the only fucking horror podcast that doesn&#39;t. What kind of jerk do you think I am? I&#39;m not that kind of jerk.</p>
<p>Bryan! (01:59.075)
Hahaha!</p>
<p>know what the fuck is now? No. So yeah, so we practically grew up in video neighborhood video stores and the state diet of utter garbage that those shops provided us with continues on a beta to this day. There&#39;s no one else I enjoy chopping it up with about Friday the 13th more than Dave. Just before we get into it, here&#39;s a little housekeeping if you want to keep up with us between the episodes, you find us on socials. We&#39;re mostly doing it on Instagram these days.</p>
<p>And you can find us over there, Bring Me the Axe Horror Podcast. And Dave&#39;s that queer wolf over there. And we&#39;re having a good time. We&#39;re doing a whole 31 days of horror thing and, you know, rolling out a whole recommendations list. It&#39;s it&#39;s been it&#39;s been fun. So, yeah, we&#39;ve also got a sweet website that bring me the axe dot com. You can listen to all our past shows there and read the transcripts.</p>
<p>You can also contact us directly at bring me the Axe pod at gmail.com with any questions, comments or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcast and you&#39;ll be doing us a favor by leaving us a five star review on Apple podcasts. And you can also do that on Spotify now. And that actually does go a long way and sort of raising our visibility. So yeah, takes a second. You know, you can just kind of push a button.</p>
<p>five stars, do it. It really, it really is. It just, it just means so much to me. So, yeah, just get to it. It&#39;s easy. Takes you no time and uh, you know, it sort of supports us in a, in a, in a meaningful way. So, I just want to get that all out of the way right at the top of the show.</p>
<p>Dave! (03:23.099)
It&#39;s all I want in the whole world. Just do that.</p>
<p>Dave! (03:40.162)
Yeah, I got the right beverage now. This is the right.</p>
<p>Bryan! (03:43.032)
That&#39;s no, no wine. No surprise wine.</p>
<p>Dave! (03:44.69)
No, no, this is a this is a Vera. Vera May is a farmstead spring blossom ale. That&#39;s fantastic.</p>
<p>Bryan! (03:55.236)
Alright, so yeah, here&#39;s uh here&#39;s a taste. He&#39;s he&#39;s gonna taste his beer. We&#39;re gonna give you a taste of what we&#39;re watching.</p>
<p>Bryan! (06:21.572)
I don&#39;t know about you, that&#39;s kind of a crappy trailer. I don&#39;t think I&#39;ve ever seen... I don&#39;t think I&#39;ve ever seen that one before. And it&#39;s not very good.</p>
<p>Dave! (06:24.086)
Yeah, I was gonna say, that is an extremely languid trailer.</p>
<p>Dave! (06:31.857)
It also says it&#39;s a Warner Brothers picture.</p>
<p>Bryan! (06:37.368)
Uh that was okay. So it was distributed here in the United States by oh yeah by it was uh by Paramount and WB got it in Europe. So that might have been a European trailer though it purports to be the original theatrical trailer, which very much very well might be because I don&#39;t think I&#39;ve ever actually seen a trailer for the original Friday the 13th before that one.</p>
<p>Dave! (06:41.942)
And I got a fun story about that.</p>
<p>Dave! (07:02.242)
Well, now you&#39;re disappointed. That&#39;s just one more way that Sean Cunningham has disappointed me. How many times do you think you&#39;ve seen this movie?</p>
<p>Bryan! (07:04.658)
I am, I am, it just counts it up.</p>
<p>Hahaha</p>
<p>a lot a lot like</p>
<p>Dave! (07:13.154)
Yeah, I don&#39;t even think I could count. I&#39;ve probably seen this like a good 30 or 40 times. If not more.</p>
<p>Bryan! (07:17.504)
I have, I, yeah, that&#39;s, it&#39;s, yeah, um, it&#39;s not my favorite of the bunch, but it&#39;s probably, I, it&#39;s easily the one I&#39;ve seen the most, and it&#39;s the one that I go back to most often for some, whatever reason. I think it&#39;s probably, yeah, yes, I would say, I would definitely say that. It&#39;s, it&#39;s, you know, it&#39;s the first one, they&#39;re figuring it out, and, um, it,</p>
<p>Dave! (07:24.277)
Uh-uh.</p>
<p>Dave! (07:31.167)
It&#39;s the least fun!</p>
<p>Dave! (07:43.17)
I mean, there&#39;s not much to figure it out. They&#39;re just ripping everybody else off.</p>
<p>Bryan! (07:47.08)
This is this is definitely true. So yeah, so uh warning before we get rolling We&#39;re basically a talk about this movie from beginning to end so spoilers to follow. What the fuck you&#39;ve seen this movie It&#39;s Friday the 13th. This is like Talking as I say tuck Yeah talking about this movie to like horror people is like asking somebody if they&#39;ve seen Star Wars, you know So, uh, yeah here eat what&#39;s that? Yeah</p>
<p>Dave! (07:58.218)
Even if you haven&#39;t, you know how it is. Surprise, it&#39;s the mother.</p>
<p>Dave! (08:09.566)
I have. I have seen Star Wars. I have seen Star Wars. I don&#39;t like it.</p>
<p>Bryan! (08:16.516)
Yeah. So, here&#39;s a, let&#39;s, oh, you know, I, I went to a school with a girl and uh who, who had never seen it. Uh and what&#39;s more, she had never even heard of it, which, which was</p>
<p>Dave! (08:17.206)
Now, come at me, internet. I don&#39;t like Star Wars.</p>
<p>Dave! (08:30.41)
Okay, that&#39;s weird. I think we just had a very similar conversation about this the other day.</p>
<p>Bryan! (08:34.588)
And and but here&#39;s the thing. So yeah, this was like this would have been I was going to school and so the trailer for the Phantom Menace had just come out and everybody was going fucking crazy for it. And she&#39;s like, what is this? What is this that you guys are going nuts for? We&#39;re like, this is the new Star Wars thing, you know, and she&#39;s like, what&#39;s Star Wars? And we are like and it was like a record scratch moment for everybody because this no, no. Here&#39;s the thing. Her name was Leia.</p>
<p>Dave! (08:56.174)
Is she raising a cult? I mean, how do you not know what Star Wars is?</p>
<p>Dave! (09:02.638)
I-I just... she was fucking with you.</p>
<p>Bryan! (09:04.708)
No, she wasn&#39;t. She was also really fucking stupid. But yeah, but yeah, that are we like, basically everybody&#39;s brain melted and came out their ears. It was it was a real weird moment.</p>
<p>Dave! (09:07.643)
Oh well.</p>
<p>Dave! (09:16.182)
Like I get it when people say like, oh, I&#39;ve never seen something, because there&#39;s a fucking million movies out there. Of course, there&#39;s gotta be stuff people haven&#39;t seen. Well, you&#39;ve never heard of something. That&#39;s something that iconic. Like it&#39;s not just like a movie. Yeah, it&#39;s a cultural touchstone. It is a property around which there have been fucking theme parks built. Like...</p>
<p>Bryan! (09:20.848)
Yeah.</p>
<p>Bryan! (09:25.292)
Yeah, yeah, like a cultural touchstone.</p>
<p>Bryan! (09:35.116)
Yeah, yeah. I mean, this was this was 1999. So like, ah, really before the kind of the sort of situation we&#39;re in now. But even then, even then it had just been in the movie theaters again.</p>
<p>Dave! (09:48.246)
Then again though, you know, I could throw out, like you could be having a conversation, like you could have a conversation with your children and say, you know, it&#39;s like Citizen Kane, and they&#39;d be like, what? What do you mean Citizen Kane? Now, it&#39;s fair to say they are young, but even when I was their age, I knew what Citizen Kane was. So there is a, I guess a generational difference, but Star Wars is a pretty big deal. I mean, there&#39;s no Citizen Kane land. I wish there was, it&#39;d be really depressing and hilarious, but.</p>
<p>Bryan! (09:57.868)
Yeah.</p>
<p>Bryan! (10:04.792)
Yeah, yeah, yeah.</p>
<p>Bryan! (10:09.644)
Yeah, yeah, yeah. There&#39;s there&#39;s. I was I was going to say there&#39;s no there&#39;s like no citizen Kane action figures, but then again, with the way that reaction puts stuff out, there might be at this point.</p>
<p>Dave! (10:17.336)
Yes.</p>
<p>Dave! (10:21.258)
At Citizen Caneland, you just throw glasses around and scream at people. Which actually sounds like a pretty great theme park.</p>
<p>Bryan! (10:29.404)
Yeah, it&#39;s probably out there. It&#39;s probably, you know, in Japan somewhere.</p>
<p>Dave! (10:33.726)
in a tiny bar in Japan, where all good things are.</p>
<p>Bryan! (10:37.984)
the movie. in Shinjuku. Yeah. So, here we are. Uh the uh the year was 1980 and uh boy, it was a good year. So, here&#39;s some, here&#39;s some other movies released that year. Maniac, which we covered in a very early episode and you can go back and listen to it which I recommend you do. It&#39;s a good one. Also, Doctor Butcher MD which is also known as uh Zombie Holocaust. I believe.</p>
<p>Dave! (10:45.559)
Man.</p>
<p>Dave! (11:01.13)
I think something like that. Yes, zombie Holocaust, yes.</p>
<p>Bryan! (11:06.652)
I&#39;m not going to say it&#39;s a movie that I adore. It is. It is. It is something. Yep. And I intend to own it because it&#39;s just that good. Uh also, New Year&#39;s Evil. Yep. And uh City of the Living Dead, the Fulci movie which I love. I love that one. I like that one more than I like the</p>
<p>Dave! (11:11.647)
It just got a blu-ray.</p>
<p>Dave! (11:20.534)
Fuck yeah, I love that movie.</p>
<p>Dave! (11:26.69)
I don&#39;t love that movie.</p>
<p>Is that the New Orleans one?</p>
<p>Bryan! (11:36.016)
The Beyond is in New Orleans. This is this is the one that takes place in Salem. Somewhere in Georgia. And lastly, City of the Walking Dead came out that year. That&#39;s Jesus. Is that that&#39;s a Berta Lenzi, right? Yeah. Yeah, I don&#39;t I don&#39;t care for that one. That&#39;s it does. It doesn&#39;t get me and that zombie makeup is fucking terrible.</p>
<p>Dave! (11:37.111)
It&#39;s gonna be a long, just normal one.</p>
<p>And by Salem, you mean Rome.</p>
<p>Dave! (11:48.918)
That movie is garbage. That is straight up garbage.</p>
<p>Dave! (11:56.105)
I believe so.</p>
<p>Bryan! (12:05.092)
But yeah, banner year, man. They&#39;re starting to, we&#39;re starting to slide into the eighties proper. So cast and crew, the director is Sean S Cunningham, whose career up to this point is real wild. So he first entered the scene.</p>
<p>Dave! (12:18.842)
I would argue his career up to this point is better than his career after this.</p>
<p>Bryan! (12:24.794)
So, yeah, he first enters the scene directing for the stage around New York. He&#39;s done some stuff on Broadway, but he got an idea in the late 60s to make a no budget porno called the Art of Marriage. It&#39;s actually a white-coder, which isn&#39;t really pornography. It&#39;s</p>
<p>Dave! (12:40.17)
It&#39;s like a, what would you call that, a nudie cutie? Is that the proper?</p>
<p>Bryan! (12:43.68)
Yeah, yeah, that&#39;s stuff that&#39;s really, really soft. The Yeah, yeah, yeah.</p>
<p>Dave! (12:49.086)
It&#39;s the shit that they used to be like, it&#39;s an educational movie. It&#39;s like, I&#39;m not crying. And I will tell you this connects to the fun fact I have for you, but keep going.</p>
<p>Bryan! (12:57.292)
Okay. So, um he made it on something like $1000 and he and he ended up making like a like a like a $100,000 off of it.</p>
<p>Dave! (13:07.65)
Well, hey, you know what? When you deal with the mob, you&#39;re gonna come out one of two ways. One of them is really wealthy, the other is dead.</p>
<p>Bryan! (13:12.205)
I&#39;m sorry.</p>
<p>Yeah. So, so he then he took some of the money and he made basically remade the art of marriage with Marilyn Chambers as movie called Together and he and he also yes, this is pornography proper and</p>
<p>Dave! (13:28.97)
That is porno proper.</p>
<p>Because this is after, together comes out after porn was legal.</p>
<p>Bryan! (13:37.196)
Yeah. So, so at this point, like beyond the green door was a big deal. And so everybody was getting in on it and you just you couldn&#39;t lose. And so they got Marilyn Chambers, who was a big deal in that one. And it just it just blossomed to turn into a whole thing. It made a fucking shit ton of money. But also he hooked up with Wes Craven on this for the first time. Who I.</p>
<p>Dave! (14:00.558)
who was also making porn at the time.</p>
<p>Bryan! (14:02.568)
Well, this was how he got into porn. And so he did this as basically it was like an assistant to cutting ham. And then he started going off and doing his own thing. But a couple of like a year after that, they get a budget to do they get a budget to do Last House on the Left. And that&#39;s when.</p>
<p>Dave! (14:22.154)
which is a gnarly movie even by today&#39;s standards.</p>
<p>Bryan! (14:25.356)
It is, I watched it just recently. And while I, I&#39;ve never really enjoyed, I can&#39;t say I enjoy it. It&#39;s a really, really brutal, very weird, weird movie with a strange sense of humor. But I definitely recommend, or I recognize its importance because this is the movie that kind of goes on to, it essentially sets the standard for horror movies in the seventies. Like all of a sudden, drive in and exploitation horror is like that. It&#39;s super.</p>
<p>Dave! (14:54.134)
and</p>
<p>Bryan! (14:54.816)
super grimy and gross and intense.</p>
<p>Dave! (14:57.314)
And I will say, y&#39;all, if you don&#39;t know what Last House on the Left is, or you&#39;re not sure whether or not you wanna see it, I will sum it up with this anecdote. The scene where they kill Mary, the protagonist of the film, there&#39;s a sort of slow shot where he pans across the, what is it, three of them, four of them killers? So he kind of pans across their faces and they all look really, they look haunted, they look horrified, and that is a genuine look because everything in that moment they said felt so real.</p>
<p>Bryan! (15:14.789)
Yeah, yeah.</p>
<p>They all look haunted.</p>
<p>Dave! (15:26.946)
that they were disgusted with themselves, they were disgusted with what they were doing. And so that is the kind of movie you&#39;re getting into. It is groundbreaking, it is very important, it is really hard to watch because it looks so.</p>
<p>Bryan! (15:34.225)
Yeah.</p>
<p>Bryan! (15:39.192)
Yeah, the thing about it that everybody kind of forgets though is there&#39;s that whole police part of the movie that&#39;s like it&#39;s got first of all it&#39;s got Martin Kove in it from the Karate Kid and they are the they are basically. Roscoe P Coltrane.</p>
<p>and another guy essentially doing the same thing. And it&#39;s like in the middle of this, like, brutal, nasty movie is all these scenes of like the cops being thwarted and them like throwing their hats on the ground and jumping up and down on them.</p>
<p>Dave! (16:09.26)
Oh yeah, fucking David Hess&#39;s soundtrack for that movie, it suggests nonstop hijinks, mad capillarity. It is like a wacky wild ride and it is so inappropriate for the rest of the film.</p>
<p>Bryan! (16:15.064)
it&#39;s yeah it&#39;s such a weird movie I don&#39;t get it because it&#39;s completely out of step with the rest of it like you could you could I it&#39;s and I don&#39;t know if they&#39;re like maybe we should shoot some funny stuff to kind of lighten things up or I just I don&#39;t know. This</p>
<p>Dave! (16:34.466)
So here, I&#39;ll tell you, here&#39;s my three degrees of separation from Sean Cunningham and Friday the 13th. So they&#39;re doing all this porn. And before porn was legal, and then even when it was legal, in Boston, a lot of it was being booked by this guy, George Mansoor, who I think at the time had a company called Hallmark or something like that. And so George, I interviewed him like seven years ago maybe. And he, because he was very important to Boston&#39;s</p>
<p>Bryan! (16:54.5)
Yes, it&#39;s Hallmark.</p>
<p>Dave! (17:03.458)
gay scene because he also booked all of the gay porn and he was just sort of very plugged in. He started the Boston Gay Film Festival. Like he was very important in that respect. He ended up getting very wealthy because he was booking a lot of pornography, which you could make shitloads of money. He told me a lot of wild stories about like dealing with the mafia and like how they would pick up movies and like and all that, but he ended up being the distributor for Friday the 13th, the original distributor.</p>
<p>And so when the movie hit and became a fucking huge success, all the studios were like, we want this movie. And there&#39;s this huge bidding war. So George, in addition to all of his pornography money, he ends up getting the Friday the 13th money. Now this man lives in, I was describing this to someone earlier. This is an apartment, I had no business being in this building. It is, it&#39;s on Beacon Street. And it was like everything in his apartment is like gold gilded. And like, I just, it&#39;s the kind of place you don&#39;t want to touch anything.</p>
<p>Bryan! (17:59.308)
It&#39;s like going to Donald Trump&#39;s house.</p>
<p>Dave! (18:00.85)
It kind of was. I was like, you don&#39;t want to touch anything because you&#39;re going to break it. And like the guy at the security desk downstairs is kind of like giving me dirty looks. Like, cause clearly I do not, but like, you know, this is a very, very fancy building. And here I am looking like I look. And it was just a really wild ride. But so I, as I&#39;m reading about it, I&#39;m like, why is that name so familiar? And I looked it up and I was like, holy shit, I know that guy. And so that is my, that is my fun story about Friday the 13th and how it got distributed by a</p>
<p>Bryan! (18:23.212)
Hahaha</p>
<p>Dave! (18:29.622)
company that primarily distributed pornography and largely gay pornography.</p>
<p>Bryan! (18:34.084)
that&#39;s really funny. Also, not terribly surprising because I think of I think that in terms of like film production on the East Coast, it was largely it was mostly centered around New York City and it was probably just impossible in the same in the same way that like when you when you cast these things or when you found your you&#39;re basically your production crew everybody was involved in either uh</p>
<p>theatrical productions, or they were also doing soaps. Like there was also probably tremendous amounts of money moving around from mafia interests. And so like, no matter where you went, you were probably never more than like two degrees removed from like hardcore pornography at the same time.</p>
<p>Dave! (19:24.066)
Because that&#39;s how you made money. Nobody made fucking money doing theater or doing these kind of low budget melodramas. Like, that&#39;s why people worked in porn. It&#39;s like you had to start somewhere.</p>
<p>Bryan! (19:31.34)
right? Well, it&#39;s just like I said, like Cunningham. Yeah, like they like, well, yeah, it was just like also what we did when we did Maniac. That was Lustig&#39;s background. Yeah, that&#39;s just there&#39;s no way no way around it. Like that&#39;s just that&#39;s it. They&#39;re they are inextricably linked.</p>
<p>Dave! (19:41.046)
No.</p>
<p>Dave! (19:48.03)
And that entire industry was controlled by the mob.</p>
<p>Bryan! (19:50.648)
Yeah. And I believe it&#39;s kind of the same thing. I mean, it&#39;s probably the same everywhere.</p>
<p>Dave! (19:55.69)
It was a little bit different on the West Coast, but they were still kind of, I mean, I only noticed because I&#39;ve written a lot about gay pornography. And it was a little different in the West Coast, but on the East Coast, it was like 100% them. And they were like, sometimes you kind of took your life in your hands.</p>
<p>Bryan! (20:11.52)
I would imagine because it was the sort of the same situation in Italy as well where there is a market decline in the sort of quality of particularly well all the genre exploitation movies because that&#39;s what the you know the mafia was interested in. They don&#39;t give a fuck about movies. They want to make money. And the way to make money is to exploit popular trends and sort of spend the least amount of money to make the biggest return. And so when the years have led and</p>
<p>really right around like 1980 or you know they end properly you start to see a decline in a lot of in a lot of uh producers you know Dario Argento is starting to slip Lucio Fulci&#39;s best days are behind him umberto lenz is not really kicking it</p>
<p>Dave! (20:43.276)
Uh huh.</p>
<p>Dave! (20:56.466)
Oh yeah, like they were either the icons were either dead or retired and their protégés were, let&#39;s be honest, far less talented.</p>
<p>Bryan! (21:03.748)
Yeah, yeah. But but most importantly, the money just wasn&#39;t there anymore and so yeah. So yeah, man. There you go. Thank god for the mob. So yeah, but anyways, so getting back to. Yeah. I did not know this. That&#39;s that&#39;s a sure guy.</p>
<p>Dave! (21:11.214)
And that&#39;s a primer on Italian film.</p>
<p>Dave! (21:19.641)
My other fun fact about Sean Cunningham, the thing that I learned, he is a very tiny man. He is 5&#39;5&quot;.</p>
<p>Dave! (21:29.034)
And I say that as a short person, and I&#39;m not that short.</p>
<p>Bryan! (21:31.772)
Yeah. So, yeah. Yeah. So, anyways, getting back, kind of getting back to last house on the left. This is a movie that really kind of in 1972 really kind of sets the pace for the rest of the seventies and then Halloween happens and it really kind of rests the grimy nasty affect away from like all of the kind of rape revenge movies.</p>
<p>Dave! (21:55.842)
Because that is what John Carpenter does not like, is gore and violence and sexual violence.</p>
<p>Bryan! (21:58.996)
Right. Like</p>
<p>Bryan! (22:03.836)
I would, well, let&#39;s talk, well, I&#39;ll give him a pass on the gore cuz it&#39;s not too long after this that he does the thing and hoo boy</p>
<p>Dave! (22:12.634)
That&#39;s kind of comedic. It&#39;s not comedic, but it&#39;s over the top. It&#39;s very theatrical. It&#39;s not realistic depictions of violence.</p>
<p>Bryan! (22:29.716)
I don&#39;t know. I don&#39;t know. at all but anyways, formula and also, I mean, numbers game. Halloween was like something like, what&#39;s it</p>
<p>Dave! (22:33.858)
something like that. It is still, I believe, it&#39;s not the highest grossing. It&#39;s one of the most profitable films ever made. It&#39;s the most profitable, maybe second now to, what&#39;s that, Paranormal Activity and Blair Witch. But I think it&#39;s like, I don&#39;t know, but it&#39;s up there. It&#39;s that big.</p>
<p>Bryan! (22:41.797)
Yeah.</p>
<p>Bryan! (22:46.188)
I believe paranormal activity is the one that holds the record now.</p>
<p>Bryan! (22:55.064)
Yeah, so Halloween comes along, essentially shifts the paradigm in a really significant way. And so, you know, these guys are all primarily they&#39;re like businessmen. And so the idea is here&#39;s a new opportunity. Let&#39;s get in on it. We can make we can spend just as much as they did and make just as much, if not more than they did, because Halloween is, like he said, major success turned out to be way bigger than anybody was expecting it to be.</p>
<p>And so they essentially</p>
<p>Dave! (23:26.474)
And you can see that in their careers that happen after this. You can tell that they don&#39;t really give a shit about movies or film. There&#39;s not a lot of... There&#39;s no auteurs among them. Like, this was all about money. That&#39;s why Sean Cunningham puts this movie out. And then maybe directs a couple other things. But for the most part, he just kind of rides on the success of this movie because that&#39;s all he really cared about. I mean, I don&#39;t want to misrepresent him. I&#39;m sure he does care a little bit. But he&#39;s not John Carpenter. He&#39;s not a...</p>
<p>Bryan! (23:47.172)
Yeah. Yeah, I think he does.</p>
<p>Bryan! (23:55.473)
I think like I can kind of get the feeling because he did continue to direct. He&#39;s primarily a producer but he did direct I think into the 90s and he directed what the like but let&#39;s all like nothing that you really remember. Deep Star six is the one that I think of.</p>
<p>Dave! (24:07.03)
No, it&#39;s Deep Star Six, it&#39;s that horrible movie The New Kids with Laurie Loughlin.</p>
<p>Bryan! (24:11.96)
Yeah, because yeah, like right before this, because it wasn&#39;t all porn for him. Like we&#39;re leading up to this. He had like his hand and other in other sort of scenes like he did. I can&#39;t remember what it&#39;s called now, but he did a Bad News Bears ripoff. They did another Bad News Bears ripoff that involves, I think, soccer. And then they did this and it was just like, oh, shit, like this is the winning formula.</p>
<p>Dave! (24:35.766)
And this is also the movie that really, this is where we get the idea of the final girl. This thing, as much as Halloween changes the game, this transforms what horror movies are.</p>
<p>Bryan! (24:41.161)
Right.</p>
<p>Bryan! (24:47.948)
right? Cuz this is what that was my next point is once again, one of his movies sets the pace like obviously Wes Craven, you know, directs Last House on the left but you know, yeah, also but I mean he was the producer on it. But like this is another Cunningham thing cuz like they go him and then also Steve Miner, I believe is editor on this and like he he&#39;s the guy who kind of propels Friday the 13th into the 80s but like this is the this is the moment.</p>
<p>Dave! (24:59.219)
and wrote it.</p>
<p>Bryan! (25:17.56)
when slashers</p>
<p>Dave! (25:18.282)
Yeah, because Steve Miner takes over after the first one. He directs the second one, right?</p>
<p>Bryan! (25:21.252)
Yeah, yeah. And so like.</p>
<p>Dave! (25:24.046)
And then I think his son ends up directing like, I don&#39;t know, fifth or sixth one.</p>
<p>Bryan! (25:28.324)
Yeah, I think I think I think Minor worked with dad a bunch too, like on like commercials, because we had as I remember duplicating like a bunch of his demo reels and stuff like that was that always kind of struck me as weird as like there&#39;s no way this is the same guy and it&#39;s like no, he&#39;s a director in New York who also happened to do a little work up this way.</p>
<p>Dave! (25:31.85)
Yes.</p>
<p>Dave! (25:45.622)
Yeah, they all did though. Like, Errol Morris did that too. Who was the other one there? Andrew Laszlo, who shot the Warriors.</p>
<p>Bryan! (25:52.152)
Yep. The Warriors. Yeah. Yep. Always, always up here. So cast, we got Adrienne King as Alice, who was a busy actress at the time, who was doing mostly commercials, and she picked this one up off of a Burger King spot, of all things. I think I found that Burger King commercial on YouTube, but it is a really, really poor transfer.</p>
<p>and I can&#39;t really spot her in it, but it was at a time when Burger King was advertising ham and cheese sandwiches. I know.</p>
<p>Dave! (26:27.31)
That&#39;s like when they did the table service. Do you remember Burger King&#39;s table service? Nobody ever remembers this. They had like five meals you could choose from. It was like, I just remember the popcorn shrimp was one of them. The cheeseburger was obviously one of them. I can&#39;t remember what the other ones were. Clearly a short-lived thing. It did not go over well. People, I guess, didn&#39;t want to go for a sit-down meal at the Burger King of all places.</p>
<p>Bryan! (26:31.468)
I do not remember Burger King&#39;s too.</p>
<p>Bryan! (26:54.564)
you go in, you get your burgers, you sit down, you eat, you got that cheesy tin ashtray that, you know, you keep, you keep it real simple. But yeah, so the unfortunate fallout of this for her was that she gained a stalker. And it&#39;s a.</p>
<p>Dave! (27:10.294)
that ended up being crippling for her. She couldn&#39;t leave her house. It&#39;s a sad story.</p>
<p>Bryan! (27:13.484)
It&#39;s a real, it&#39;s a, it&#39;s a fucked up story. I believe he held her hostage for a little while. Like it&#39;s, yeah, it&#39;s a real sad story and it caused her.</p>
<p>Dave! (27:21.774)
She recently, she was on the Peaches Christ and Michael Verrani&#39;s show, Midnight Mass, and she talks about it. She goes into it quite a bit in that. It&#39;s a really terrible story.</p>
<p>Bryan! (27:33.784)
Yeah, but like II only know like the broad details of it. I have no desire to really look into it because it&#39;s just it&#39;s not my business and that&#39;s really sad, but it was because it was bad enough to cause her to sort of withdraw she stayed working, but she was doing she had a lot of voice stuff like looping and ADR.</p>
<p>Dave! (27:48.43)
She was voice acting.</p>
<p>Bryan! (27:53.144)
And then eventually when the sort of autograph industry kind of roared to life, she kind of came back into the scene and started doing like, she sort of shows up in horror movies every now and then.</p>
<p>Dave! (28:03.446)
Yeah, she ended up, someone like convinced her to go to Chiller one year. Um, and she did, like, she was like, all right, I guess. And she was one of those people that like, didn&#39;t realize she had a following. It&#39;s kind of like Betty Page. Like when that guy found Betty Page, she was like, don&#39;t you understand you&#39;re like an icon? She&#39;s like, who are you? What do you want? No, I live in the woods. Fuck off.</p>
<p>Bryan! (28:16.964)
Yeah.</p>
<p>Bryan! (28:20.702)
Yeah.</p>
<p>Yeah. So, uh yeah, but like it brought her out. It kind of, yeah, like you said, found her audience and she&#39;s she I&#39;ve never met her. She seems like one of those people who really gets it though.</p>
<p>Dave! (28:33.302)
Yeah, she seems very, like genuinely appreciative. She also is a painter.</p>
<p>Bryan! (28:38.548)
I wondered if the art in the movie is hers, cause it&#39;s a detail that appears very quickly in this one and also in the sort of beginning of part two. So yeah, it must be her. Also appearing is Betsy Palmer as Pamela Voorhees, who I met at one of these horror conventions and she holds the record for the nicest celebrity I&#39;ve ever met. She is the polar opposite of my encounter with Tom Savini. I could have spoken to her.</p>
<p>for hours. It was one of those deals where like when you I used to go to these things and I like to get DVDs signed and before it became just way too expensive to do that sort of thing but like you&#39;re always in a line with a bunch of people behind you and you&#39;re constantly conscious of like I don&#39;t want to take up this person&#39;s you know, I don&#39;t want to monopolize this because there&#39;s people but there for some reason there was nobody there for Betsy Palmer. Yeah, that&#39;s the other thing.</p>
<p>Dave! (29:31.186)
And it&#39;s like weirdly transactional too, so you&#39;re kind of acutely aware of that.</p>
<p>Bryan! (29:36.252)
But in this case, she held us there for like, I swear to God, like 30 minutes, just talking, just chatting.</p>
<p>Dave! (29:44.27)
Well, because this like made her career. Cause she didn&#39;t, I mean, people talk about her like she was some like, you know, legendary star. She really wasn&#39;t. She had done some stuff.</p>
<p>Bryan! (29:53.08)
Now, she was a real, she was a minor leaguer in the 50s in terms of film, but like her home was really on the stage in New York. And that doesn&#39;t really like if you&#39;re into stage and theater, then like you probably are aware of her in some in some way but like</p>
<p>Dave! (30:11.022)
But she is iconic because of this movie. And can you, I mean, so there were two other people they had offered it to before her. One of them is Estelle Parsons. Was it? One of them is Shelley Winters. Can you imagine Shelley fucking Winters in this role?</p>
<p>Bryan! (30:13.22)
because of this movie.</p>
<p>Bryan! (30:19.128)
one of them was Sally, one of them was Sally Fields. Yeah. Oh no shit.</p>
<p>No, no, I got that wrong. They wanted Sally Fields for Alice, and when they got in touch with her agent, they were like, we cannot afford that.</p>
<p>Dave! (30:37.526)
No, and Estelle Parsons is, she&#39;s like an Oscar winner. And Michelle Winters is an Oscar winner too, and she&#39;s also a lunatic.</p>
<p>Bryan! (30:40.74)
Yeah, yeah. But like, the thing, some of the things that I learned about this, about basically the development of the project was that on top of everything else, and I mean, it probably just comes with being a businessman, Sean Cunningham was into like stunt marketing.</p>
<p>Dave! (30:57.098)
Oh yeah, this is a real fake it till you make it story.</p>
<p>Bryan! (30:59.62)
Yeah, and so I think a lot of that was like, they knew they were never gonna get these big names, but they put the script out there to sort of get it circulating in Hollywood. Like, before they even had a finished script, he took out like a full page ad for the movie in variety, but also, I mean, mostly for hype, but also to sort of dip a toe in it to find out if there were any other movies called Friday the 13th, and surprise,</p>
<p>There actually is a year before this movie came out. There&#39;s a movie called Friday the 13th, The Orphan, which there was some litigation. It sounds like they settled out of court. I cannot find any details about it, but I suspect that somebody wrote a check because that movie now just goes by the title The Orphan.</p>
<p>Dave! (31:46.89)
And the only reason this is called Friday the 13th is because of Halloween. Is that right?</p>
<p>Bryan! (31:51.1)
Yeah. Uh yes. Yeah. So, the original title of the movie is a Long Night at Camp Blood which is a <em>**</em> mouth. Stupid title. Uh they called it Friday the 13th because of Halloween but also ominous connotations. You know, it&#39;s it&#39;s just it&#39;s again, it&#39;s stunt marketing. Uh there&#39;s a lot to this movie that sort of feels like other movies and so like that&#39;s just that&#39;s just uh like a bit more of it. So,</p>
<p>Dave! (31:56.614)
Ugh, fucking worst title.</p>
<p>Dave! (32:15.518)
Oh yeah.</p>
<p>Bryan! (32:20.324)
Betsy took this because she needed a new car and it cost $10,000 and they were going to pay her $1,000 a day for 10 days. That was it. That&#39;s how she took the role.</p>
<p>Dave! (32:30.914)
Which is wild, because it doesn&#39;t feel like she does 10 days of work in this movie. I mean, at the end, she really, she earns her money, but...</p>
<p>Bryan! (32:34.616)
know because she&#39;s oh yeah um like yeah again like what do I know about like film production but like ten days seems like a lot given for how much she&#39;s actually in the movie because most of the kill scenes are savagely</p>
<p>But yeah, so.</p>
<p>Dave! (32:52.778)
And I&#39;ll tell you what, when you watch this in 4K, you can tell.</p>
<p>Bryan! (32:57.549)
Yeah, yeah. And rounding off the cast, Kevin Bacon as Jack.</p>
<p>Dave! (33:01.29)
Never heard of him. He is yet another bit player in things. A movie you&#39;ve never seen called Footloops.</p>
<p>Bryan! (33:06.712)
Yeah, he is uh yeah for a long time it seems like he went out of his way to avoid ever having to acknowledge that he was in this movie and it I remember I was gonna say he I was gonna say I&#39;m told that he&#39;s a really nice guy and has a really good sense of humor about it but like into the</p>
<p>Dave! (33:20.47)
Not anymore though, he has a much better, same thing with tremors, he has a much better sense of humor now.</p>
<p>Bryan! (33:31.356)
90s, I would tell people, oh, yeah, this is Kev Bacon&#39;s Friday the 13th and they&#39;d be like, shut the <em>**</em> up. That guy? No. You know, but yep, this was it. This is one of his, one of his first, one of his first things.</p>
<p>Dave! (33:43.138)
Cause what he did, like, I think he did Diner before this. And that&#39;s it. That movie sucks.</p>
<p>Bryan! (33:45.784)
Yeah. Yep. But like also again, lots of commercials and you know, and stage because it was all centered around New York.</p>
<p>Dave! (33:54.966)
And then right after this, he went on to do a fantastic television film called The Demon Murder Case with Cloris Leachman. That is the one based on Ed and Lorraine Warren&#39;s big fucking scam where they tried to convince everyone that the guy killed somebody because of the devil. Guess what? He didn&#39;t. Devil&#39;s not real, neither are demons. Sorry, Ed and Lorraine Warren.</p>
<p>Bryan! (34:02.873)
I don&#39;t know that one.</p>
<p>Oh God, the devil made me do it.</p>
<p>Bryan! (34:11.96)
Yep.</p>
<p>Bryan! (34:18.832)
So yeah, some notes. This movie was released on May 9th, 1980. It came out at the same time. I don&#39;t know if it was the exact same time, but it was in theaters simultaneously with The Empire Strikes Back. And this was, this movie kind of marks probably my first conscious memory of sort of interacting with horror. Because Dad took me to see The Empire Strikes Back.</p>
<p>Dave! (34:46.654)
I thought you were going to say he took you to see Friday the 13th.</p>
<p>Bryan! (34:49.068)
No, no, but he took me to a movie theater. He has more sense than that.</p>
<p>Dave! (34:51.391)
I wouldn&#39;t be surprised if you-</p>
<p>Dave! (34:56.191)
I mean, we had sort of a weirdly dichotomous home in which one of our parents thought that these were all evil and needed to be put in the trash, literally, and the other one thought movies were great and you should just watch all of them.</p>
<p>Bryan! (35:06.696)
Yeah, yeah, which is wild because mom represents like my first meaningful interaction with horror since all the fucking horror titles on our bookshelves were hers, including The Exorcist and the H.P. Lovecraft stuff that we had, which is crazy because like when I was like, hey, I&#39;d like to see Friday the 13th and she was like, absolutely not. It&#39;ll make you a serial killer. It&#39;s it&#39;s pretty it&#39;s pretty wild. But yeah, I was in a movie</p>
<p>Dave! (35:30.742)
No.</p>
<p>Bryan! (35:35.944)
basically going to see the Star Wars movie and that poster was up and you know you don&#39;t you can you&#39;re seeing it in your head right now is even as I speak to you and it&#39;s a it&#39;s a very evocative poster and when you&#39;re like four years old.</p>
<p>Dave! (35:50.61)
And yet it tells you nothing at the same time.</p>
<p>Bryan! (35:52.884)
Yeah, but like when you&#39;re four years old, it&#39;s like enough to really send your mind spinning. And like I was obsessed with that poster, you know, in a really, really weird way. I thought about it a lot because it was the same like you just said, like, you know, it doesn&#39;t give you any idea what it&#39;s what it&#39;s about. But like, it&#39;s fucking scary to look at, you know, when you&#39;re when you&#39;re that little. And so it really stuck with me. And eventually, you know, I caught up with it, obviously. And it was not what I was expecting it to be.</p>
<p>Bryan! (36:25.734)
So yeah, it was made for half a million dollars and it pulled down 60 million in its initial theatrical run and it cemented cheap slasher movies as the dominant paradigm of the 1980s. Also, it&#39;s notable for being the first indie movie to secure major studio distribution.</p>
<p>Dave! (36:43.278)
It is also still very controversial in terms of who owns what. That box set was like a huge legal undertaking to put out apparently. Because it&#39;s owned by like four different or three different groups, I think.</p>
<p>Bryan! (36:49.34)
Eeeeee, ahhh.</p>
<p>Bryan! (36:55.253)
Oh Jesus, it&#39;s gotta be.</p>
<p>Bryan! (37:02.028)
Yeah, right, because eventually it passes out. I think Dimension gets it. New Line gets it. Yeah. Yeah, did you know Dimension is?</p>
<p>Dave! (37:05.502)
New Line bought it. New Line bought it in then Dimension, I think. Because I think Miramax put out the shitty last one, the remake.</p>
<p>Bryan! (37:14.008)
Yeah. Did you know Dimensions owned by Disney? Always has been. I did not know this. I did not. I did not know this or either. I never really connected the dots, but like that&#39;s fucking. Disney owns everything. But like even back then, like that was that was crazy to me that like fucking Disney was the company responsible for some of the most important horror movies of the 1990s, which is just fucking nuts to me. So all the camp scenes were shot on location.</p>
<p>Dave! (37:17.29)
Yeah, because Disney owns Miramax and Miramax owns Dimension.</p>
<p>Dave! (37:24.97)
I fucking, Disney owns everything.</p>
<p>Dave! (37:37.803)
Mm-hmm.</p>
<p>Bryan! (37:44.352)
at a camp called Camp Nobe Bosco. No, it&#39;s I&#39;m sure I&#39;m no, I&#39;m sure that Bosco stands for like Boy Scouts, because like the fucking Boy Scouts think they&#39;re all cute. It&#39;s.</p>
<p>Dave! (37:47.711)
Mmm sounds like a native word. No, no, it&#39;s not</p>
<p>Dave! (37:57.334)
Oh, it is. Yes, it is. Because that&#39;s still who owns it. When they do the, when they do those like big viewings at the camp, they donate like half of the money. That was how they ended up getting the deal. Like they were like, we will, they were allowed to show the movie at Crystal Lake Camp, whatever the fuck it&#39;s called, because they were willing to donate like half of the money to the Boy Scouts. Which, you know, if you&#39;re gonna give money to a group, I don&#39;t know if that&#39;s the group to be giving it to.</p>
<p>Bryan! (38:04.472)
Yeah.</p>
<p>Bryan! (38:22.524)
to not them. No, but like yeah, because like the fucking Boy Scouts think they&#39;re real. Yeah, they&#39;re but they&#39;re real cute like with names and shit like that. Like fucking the wee below stands for like we&#39;ll be loyal Scouts or some shit. So like they&#39;re always playing with. Yeah, I know. Anyways, it&#39;s located in Hardwick, New Jersey, which looks like it might be a couple hours northwest of Manhattan. It&#39;s right on the border of Pennsylvania. You can go there.</p>
<p>Dave! (38:27.774)
Maybe a less handsy group. Just...just saying. Just saying.</p>
<p>Dave! (38:39.908)
UGH</p>
<p>Dave! (38:48.714)
Also, if anyone wants to know why there are so many movies that take place at summer camps, it&#39;s not just because they&#39;re aping this movie, it&#39;s because it is a single location that you can stay at while you&#39;re filming, and it has the infrastructure for food, for accommodations, for pretty much everything, and if you do it off season, it&#39;s really fucking cheap. That&#39;s why there are so many goddamn camp horror movies.</p>
<p>Bryan! (39:10.108)
Yeah, I was talking to the to some people about this the other day because I don&#39;t remember which. Oh, I think we were talking about Sleepaway Camp. And it was the fact that all of these movies are shot while these camps are off season. And so everything is supposed to look like it&#39;s in the summer. But whenever. Yeah, it&#39;s always shot like October or November. And it&#39;s really, really fucking cold that like even on the West Coast.</p>
<p>Dave! (39:29.966)
except it&#39;s October.</p>
<p>Bryan! (39:39.476)
it gets pretty cold at night, you know, in the winter in the winter months. So like</p>
<p>Dave! (39:43.598)
To which was it, was it, it was like Madman or it was Sleepaway Camp? It&#39;s one of them where they talk about how someone was literally going around gluing leaves onto trees.</p>
<p>Bryan! (39:51.924)
Oh yeah, that was Sleepaway Camp. But or no, that was Madman. I think Sleepaway Camp is the one where they painted them. But yeah, but yeah, also, like, I mean, whenever you watch these, particularly some of the Friday the 13th sequels, like you can see people&#39;s breath. You can see them freezing to death as they shoot these scenes in the in the camp. It&#39;s just it&#39;s crazy.</p>
<p>Dave! (40:12.694)
Yeah, this is one of those movies that does not benefit from 4K or from any kind of restoration like that.</p>
<p>Bryan! (40:18.744)
Oh yeah. Yeah. But uh yeah. So like you said, this kid, this place actually continues to operate as a Boy Scout camp and I couldn&#39;t imagine going to that place when this movie was all the range because like it must have been, it must have been fucking awesome. But you can get tours of the location if you if you&#39;re into that sort of thing. I happen to be that kind of a dork but I&#39;ve looked up prices for those tours and I&#39;ll tell you what, no thanks. It&#39;s a little expensive.</p>
<p>Dave! (40:41.728)
Yeah.</p>
<p>Bryan! (40:46.368)
So special effects are all by Tom Savidi.</p>
<p>Dave! (40:48.91)
who got this based entirely on his work on Dawn of the Dead.</p>
<p>Bryan! (40:56.19)
the Dead. Yeah. So, his, so, movie was to make it basically where Halloween is a bloodless. He wanted to up the</p>
<p>Dave! (41:09.378)
Yeah, he was like, what if we make our version of Halloween except we don&#39;t give a shit about the story, the dialogue, or the characters? It&#39;s basically that.</p>
<p>Bryan! (41:18.912)
I&#39;m going to give you, I&#39;m going to give this movie a pass on the characters because at a certain point while I was watching it, I&#39;m like, you know what? I do kind of like all of these people. Even Daddy, all of them. I think they&#39;re, I think they&#39;re, they&#39;re not bad. They&#39;re not bad.</p>
<p>Dave! (41:28.502)
Oh, I fucking hate them.</p>
<p>Dave! (41:33.226)
Actually, no, I think Adrienne King, I think she&#39;s good. But there&#39;s all the fucking tertiary characters are the ones that I love.</p>
<p>Bryan! (41:35.949)
I think she&#39;s lovely.</p>
<p>Bryan! (41:39.804)
I think like Tierney Ralph. Yeah. But anyways, like the reason for this is because all of the stuff that have been produced up to this point for the most part in terms of horror movies, specifically these little indie ones is you&#39;re kind of confined to making because also special effects makeup was not really a thing that could be done on the cheap.</p>
<p>Dave! (41:43.095)
Don&#39;t worry, we&#39;ll get it. We&#39;ll get there. We&#39;ll get to it.</p>
<p>Bryan! (42:04.876)
And so a lot of the a lot of the special effects are retractable knives and the fucking, you know, hidden blood packs with the, you know, the bleeding knife gag and stuff like that. And so they would imply the violence and then show you the aftermath. And what they wanted to do with this one was show it happening as it happened. And so Svini was the only guy in the game at the time.</p>
<p>Dave! (42:22.378)
Yeah, there&#39;s a reason why he is an icon. He is really fucking-</p>
<p>Bryan! (42:26.36)
he&#39;s the first guy who did it. I think he eventually he gets like outshined by his protegees but up until you know the Greg Nicotero comes into the picture like he was really the only game in town.</p>
<p>Dave! (42:40.138)
And Greg Kattera doesn&#39;t come on until what, Dave did, 85, 86?</p>
<p>Bryan! (42:42.768)
day of the dead is yeah. Yeah. So, uh yeah, nobody else is doing it and so like and that&#39;s the thing is this the script has a lot of really sort of exciting stunt kills in it. And then uh let&#39;s see the uh last lastly, can&#39;t go anywhere without mention of the music. The film&#39;s music is a central piece of why this all works. Uh it&#39;s by a composer named Harry Manfred and it is awesome. Like like</p>
<p>Dave! (43:07.178)
And interestingly, like, so the, it&#39;s not a coincidence that so many of these people worked in soap operas. I think they all kind of knew each other. Like Victor Miller also worked, he wrote for soap operas.</p>
<p>Bryan! (43:18.148)
Victor Miller, yeah, he was, yeah, and he lavished the opportunity to write a movie where he gets to kill everybody.</p>
<p>Dave! (43:23.678)
And you can tell, there are points in this movie where you can feel the soap opera-ness. Like in the music, in the dialogue, in the direction, you can really feel it. And I personally think it&#39;s awesome, but.</p>
<p>Bryan! (43:29.154)
Yeah.</p>
<p>Bryan! (43:37.272)
Yeah, and he&#39;s like a lot of the guys who were operating like fast and cheap at this time, and they lifted all of their themes from like other stuff. And it&#39;s always the same fucking it&#39;s always the same one. It&#39;s always psycho.</p>
<p>Dave! (43:48.882)
Oh yeah, no, fucking Bernard Hermione&#39;s just slathered all over this. This whole, 90% of Harry Manfredini&#39;s score, I would say, spends its time, as Strongbad would say, way up high on the tiny strings. It is staccato notes pretty much all the way to the end.</p>
<p>Bryan! (43:53.476)
Yeah, yeah, but also...</p>
<p>Bryan! (44:07.148)
Oh, and the this the string sweeps the stabby shit but yeah but also there&#39;s a lot of John Williams&#39;s jaws in this one.</p>
<p>Dave! (44:16.682)
And all John Williams was ever really doing was ripping off Bernard Harmon too, so... Still does.</p>
<p>Bryan! (44:21.293)
Yeah, but regardless of those of those origins, no suite of musical themes communicates running through the forest and terror quite like this one. And the obvious lasting late motif in this is the iconic kiki kibama ma thing that they do, which it&#39;s key. Yeah, so it&#39;s care as in kill, not ch that a ch sound get that through your fucking skulls.</p>
<p>Dave! (44:38.67)
which is pulled from a line later.</p>
<p>Bryan! (44:49.236)
I know it drives me goddamn crazy that people are still doing that. Killer and ma as a mommy. God damn it. Yeah, we can do so the film. Yeah, I know, I know. That&#39;s what I do. It&#39;s my fucking podcast. I&#39;ll freak out if I want to. Yeah, so the film opens on Camp Crystal Lake in 1958 where campers and camp.</p>
<p>Dave! (44:51.522)
Because it is killer, mommy.</p>
<p>Dave! (45:02.406)
I guess this is what happens when you piss off a dork, I guess. They freak out on their podcast.</p>
<p>That&#39;s a grim line.</p>
<p>Dave! (45:17.73)
Jesus, 45 minutes later we&#39;re finally talking about the fucking movie.</p>
<p>Bryan! (45:20.668)
Thanks for watching!</p>
<p>Yeah. We are concise, if anything. So campers and counselors sing happily around a fire while in first person perspective, we get a tour of the camp by night set to the tents.</p>
<p>Dave! (45:34.791)
This is a real Christian camp.</p>
<p>Bryan! (45:38.101)
It really is. They&#39;re singing like, what&#39;s that fucking song? Michael Rowe the Boneless Short? Yep.</p>
<p>Dave! (45:41.186)
Hanged on your head, Tom Dooley. And then there&#39;s like a couple other Christian haves they toss in there. Yeah, these are a lot of real happy white kids.</p>
<p>Bryan! (45:48.62)
Yeah, yeah. But also like this is this is essentially this is them just doing Halloween.</p>
<p>Dave! (45:55.874)
Yep. Also Jaws. That moment around the fucking fire by the camp, or not the campfire, by the, that&#39;s Jaws. It&#39;s 100%. It&#39;s all real wholesome until Barry and Claudette sneak off to fuck.</p>
<p>Bryan! (46:01.554)
Oh my god. You&#39;re right. Yep. It starts right out. It starts right off there but like yeah, it&#39;s the</p>
<p>Bryan! (46:09.532)
Yeah, and they yeah, pair of counselors break off to a cabin somewhere where they are going to do it. They&#39;re all making out and <em>**</em> when more of that tends</p>
<p>Dave! (46:18.543)
I will point out that there is a shot, there&#39;s a moment where the killer is creeping around the camp that you actually see children sleeping in beds. And it is a rare appearance of children in this series that takes place primarily at a children&#39;s camp.</p>
<p>Bryan! (46:27.3)
Yes!</p>
<p>Bryan! (46:34.744)
Yeah, yeah, I had never really noticed that. And I think maybe it is, it&#39;s just the 4K-ness of it because it&#39;s a really dark scene in terms of lighting. And it&#39;s like, oh, there&#39;s people in these beds. But like when you can see it in the UHD presentation, it&#39;s like, oh shit, those are children.</p>
<p>Dave! (46:51.202)
because you don&#39;t actually get kids in this series until the seventh.</p>
<p>Bryan! (46:55.076)
Yeah. And so, yeah, we get that POV stuff. And so what Manfredini said when they were making this is that he did he does this on purpose throughout the entire movie. There is only music present when the killer is doing their thing, right? Because there are scenes early, like when Brenda is at the archery range and Ned does the thing with the arrow, like it&#39;s set up to kind of give you the impression that she&#39;s going to be killed by the killer.</p>
<p>but there&#39;s no music in this scene. It&#39;s only when, you know, the killer is doing their thing. And so, yeah, the killer interrupts these two, stabs the boy, chases the girl around before we get.</p>
<p>Dave! (47:33.278)
Meanwhile, but before that happens, they&#39;re like making out and rolling around and giggling. And I believe he says, it&#39;s not made of stone.</p>
<p>Bryan! (47:40.884)
Ha ha ha!</p>
<p>Dave! (47:42.162)
No, I don&#39;t, I mean, I think we all know what he&#39;s talking about, but I don&#39;t know what preceded that.</p>
<p>Bryan! (47:45.104)
Yeah.</p>
<p>Bryan! (47:48.376)
Yeah. Maybe it was an improvised line. Yep. And so, yeah, they do the whole thing. They they do the, you know, they do that slow mo zoom thing that&#39;s really, I don&#39;t know what it is. It seems it seems like it&#39;s unique to this series because like Friday the thirteenth does it a bunch. Uh also the trailer for Sleepaway Camp does it like a bunch as well. Like I bet they were stealing all their shit from Friday the thirteenth anyways.</p>
<p>Dave! (47:51.527)
Anyway</p>
<p>Bryan! (48:17.124)
like, yeah, this is this is the beginning of Halloween. POV from the killer. We got some sexy times and then uh but like in the in Halloween, obviously, we go down to the, you know, street level and they pull the mask off and it&#39;s a little boy. In this one, you know, they&#39;re saving the killer reveal for the end and like, you know, you, you know, I&#39;ll dance around it on this just for the hell of it but everybody fucking knows.</p>
<p>Dave! (48:41.53)
I already blew it at the beginning.</p>
<p>Bryan! (48:42.68)
Yeah, everybody knows who the fucking killer is anyways. There&#39;s no there&#39;s no mystery left in this one.</p>
<p>Dave! (48:46.954)
It&#39;s impossible to talk about this movie without saying who the killer is.</p>
<p>Bryan! (48:50.808)
Yeah, but like the like I mean there wasn&#39;t that many like body count movies like this at the time and it&#39;s the killer is always a dude and so like the fact that they kind of like pull that rug out from under you at the end and really kind of uh subvert your expectations was it was interesting for the time and it&#39;s it still kind of remains that way.</p>
<p>Dave! (49:12.811)
It&#39;s still a fun review.</p>
<p>Bryan! (49:15.232)
I love it because the way that we&#39;ll get there, but when she shows up, she&#39;s a lot of fun. For a moment, you&#39;re like, oh shit, thankfully, here comes help. But, you know, she...</p>
<p>Dave! (49:24.374)
Yeah, this movie makes a lot of use of, oh, it&#39;s you.</p>
<p>Bryan! (49:28.764)
Yeah, yeah. Actually, they don&#39;t overdo it quite as much as they do in in like in sleepaway camp. They do it like every five minutes. But uh yeah, so yeah, this is them just gleefully stealing from Halloween. Uh but yeah, so now we cut to the present day and it&#39;s a rural backwater township. What is a <em>**</em> township anyways?</p>
<p>Dave! (49:32.683)
Oh, yeah, I could have used more.</p>
<p>Dave! (49:51.278)
I believe it is a something an unincorporated area within a town or something like that.</p>
<p>Bryan! (49:58.044)
Okay, so like bigger than a village, smaller than a town. Yeah. Yeah, everything&#39;s a township in New Jersey. And Annie is backpacking her way to Camp Crystal Lake. She pops it.</p>
<p>Dave! (50:01.451)
I believe so, yeah.</p>
<p>Dave! (50:11.67)
Was she the one who got cast because she looked like a camp counselor?</p>
<p>Bryan! (50:16.348)
What? Maybe?</p>
<p>Dave! (50:17.754)
One of them hadn&#39;t even auditioned yet and they were like, nope, you&#39;ve got the look, you&#39;re in.</p>
<p>Bryan! (50:23.885)
If it&#39;s anybody, it&#39;s definitely her.</p>
<p>Dave! (50:25.846)
because she looks 100% like a fucking camp counselor.</p>
<p>Bryan! (50:28.94)
Yeah. She pops into a diner and drops the name Camp Crystal Lake, which makes the whole place freeze.</p>
<p>Dave! (50:34.59)
I love this moment because the woman behind the counter turns down the radio. I tell you what, if she had a record player, she would have just dragged the needle right across the record.</p>
<p>Bryan! (50:39.789)
Yeah.</p>
<p>Bryan! (50:43.712)
Yeah, real slow like. But this is the thing. It makes me wonder because I was thinking about this as I&#39;ve always just kind of taken it for granted that Crystal Lake has this reputation, but I never really thought about it. To the best of our knowledge. There is one murder.</p>
<p>Dave! (51:03.054)
Well, so here&#39;s what I&#39;m saying is, I don&#39;t understand why. Because they say later on why is, you know, they&#39;re in the car or whatever. And he&#39;s like, well, there was the boy that drowned. And then there were those two murders. And then the water was. I&#39;m like, this shit all just sounds like kind of par for the course for a can. Yeah. I mean, this does not equate to death curse for me. This feels like they&#39;ve been working on the apartment above mine. I swear to God, I&#39;ve endured all of those things.</p>
<p>Bryan! (51:12.302)
Yeah.</p>
<p>Bryan! (51:20.44)
Yeah, look, there&#39;s there was a bunch of fires and the water was bad and it&#39;s like that. What this that&#39;s a death curse. No.</p>
<p>Bryan! (51:35.297)
like, yeah, like, this is, you going there? It&#39;s like, happened like 30 years ago and understand it. It&#39;s just, it&#39;s there had been, if they backed you know, somebody gets killed they just stop trying to open that would be something they</p>
<p>Dave! (51:52.622)
But, you know, these are real slack-jawed yokels, though. These are the slackiest jawed yokels.</p>
<p>Bryan! (51:58.904)
Yeah, one of those is Erwin Keys in a real early role. And the busboy. Yep, really young. I guess the year before he played, he was a cop in the Warriors. So, yeah, eventually he shows up and basically everything Rob Zombie was doing.</p>
<p>Dave! (52:04.087)
Which one?</p>
<p>I didn&#39;t notice him.</p>
<p>Dave! (52:19.618)
Look, Rub Zombie&#39;s running out of people he can put in movies.</p>
<p>Bryan! (52:22.724)
I&#39;m not going to go back to there with Rob Zombie. Uh so My thing with Rob Zombie is he cast his movies as he goes to is in town and he&#39;s like, okay, uh you&#39;re coming</p>
<p>Dave! (52:26.231)
Somebody fucking shore up Dee Wallace.</p>
<p>Dave! (52:44.405)
Oh Jesus, you can&#39;t put me on the spot like that.</p>
<p>I don&#39;t know.</p>
<p>Bryan! (52:49.212)
I don&#39;t know. I&#39;ll cut this out and then we&#39;ll do it again in the Halloween episode.</p>
<p>Dave! (52:54.839)
professionalism the aristocrats</p>
<p>Bryan! (52:56.28)
Yeah. So, yes. No. So, Andy catches a ride out to Crystal Lake with a truck driver named Venus.</p>
<p>Dave! (53:06.022)
Ugh, I would not- if I was a young lady I would not be getting in a car with Enos. He looks like he is a man who takes liberties. And he does when he helps her into the car. Just puts fucking bold hands on her ass, pushes her up into the- It&#39;s like, I don&#39;t know if that&#39;s- is that, uh, in the script or is this just like 70s guy behavior?</p>
<p>Bryan! (53:12.302)
You know what, if this movie-</p>
<p>Bryan! (53:23.436)
a little both probably, you know? But I, the thing is, is if this movie were made today, that he would have had his hands all over. It would have been like just, it would have been grotesque in the extreme. In this one, yeah, like in this one, it&#39;s just, you know, he&#39;s, he&#39;s a little, he&#39;s a little handsy and then, and then he kind of gives her, gives her the side eye, but like it&#39;s, it&#39;s</p>
<p>Dave! (53:35.359)
Yeah, he doesn&#39;t leer at her.</p>
<p>Dave! (53:44.507)
Oh, but before they get into the tr-</p>
<p>You get a warning. You get a dire warning from your harbinger.</p>
<p>Bryan! (53:48.96)
Oh, that&#39;s right. Yeah. So yeah, before they&#39;re costed by crazy Ralph, who informs her that she&#39;s going to die because it has a death curse.</p>
<p>Dave! (54:00.782)
Here&#39;s what I think about Crazy Ralph. I feel like he is a guy who knew he has a limited amount of time on the screen, and he is gonna make the most of it. Because he just puts his whole self into this performance. He&#39;s just, fuckin&#39;, shoulders rollin&#39;.</p>
<p>Bryan! (54:08.708)
he&#39;s oh yeah and that&#39;s the thing is he is one of the details of this movie that everybody remembers and everybody loves cuz like they loved him so much that they rolled him out in part two. Yeah but like</p>
<p>Dave! (54:23.954)
I will say, it&#39;s kinda hard to- he tries to make a dramatic exit, it&#39;s kinda hard to make a dramatic exit on a rickety bicycle with a basket on the front, but he does his best.</p>
<p>Bryan! (54:34.044)
I&#39;m like, oh, yeah. Yep. loves crazy Ralph. So, the fucking bothers me is he is set like the guy that you&#39;re supposed to jump to when you&#39;re these people? But it&#39;s a, it&#39;s doesn&#39;t really settle with me man who can barely ride a next, like in 10 minutes, we&#39;re</p>
<p>Dave! (54:49.526)
Not him.</p>
<p>Dave! (55:00.342)
He is just, he is just fucking as sassy as he pleases. He&#39;s not out there killing people.</p>
<p>Bryan! (55:04.9)
No, and like in one of the kill like one in ten minutes, we&#39;re going to see Andy get killed by somebody driving a Jeep and I do not believe for a second that this man has the presence of mind to operate a moving vehicle. No, but uh yeah, so um that&#39;s crazy Ralph. So um as they&#39;re driving like Enos lays out all the bad shit that&#39;s happened that we just kind of went through and I&#39;m still not.</p>
<p>Dave! (55:28.15)
These two have weird chemistry though. Like the way that they are playing it is like, they kind of have this, like Edith, Edith and Archie Bunker is what I&#39;m getting. Like they&#39;re just kind of sassing each other back and forth. He&#39;s like, you kids. And she&#39;s like, you people, I&#39;m not afraid of ghosts at least. It&#39;s like, you just met him five minutes ago. Why are you sassing him?</p>
<p>Bryan! (55:33.121)
It&#39;s very fatherly.</p>
<p>Hahaha</p>
<p>Bryan! (55:42.693)
Yeah.</p>
<p>Bryan! (55:48.72)
they&#39;re friendly man so he</p>
<p>Dave! (55:50.57)
It doesn&#39;t feel friendly though, it feels kind of antagonistic.</p>
<p>Bryan! (55:53.788)
they are a little adversarial but like the energy is paternal is the way that I describe it.</p>
<p>Dave! (56:01.023)
Okay, alright, yeah, I get that, I get it.</p>
<p>Bryan! (56:04.76)
So, yeah, he drops her off and now we cut to three more counselors driving up to Crystal Lake. That&#39;s Jack played by the bacon eater, his girlfriend Marcy, and their friend Ned, and it&#39;s all it&#39;s all set to a shredding banjo solo which it seems like every fucking movie of the era, the soundtrack features ripping banjo action like</p>
<p>Dave! (56:16.322)
That fucking sucks.</p>
<p>Dave! (56:29.47)
Oh, there&#39;s a, there is a almost a direct ripoff in My Bloody Valentine.</p>
<p>Bryan! (56:35.948)
Yeah. And also, I mean, like, I feel like unless I&#39;m remembering it wrong, I seem to remember like a kick ass banjo track in Dawn of the Dead as well. Yeah.</p>
<p>Dave! (56:44.842)
Yes, yeah, it&#39;s when they&#39;re when like the hunting party&#39;s going out</p>
<p>Bryan! (56:49.72)
Yeah. So yeah, you got to.</p>
<p>Dave! (56:51.855)
And I was wondering, do you think they asked Goblin to do that? And they were like, I&#39;m sorry, what is a banjo?</p>
<p>Bryan! (56:55.624)
No, that&#39;s... It&#39;s like a mandolin! No, that&#39;s gotta be... Yeah, that&#39;s gotta be like library shit. But like, whenever you get these soundtracks, they always come with the fucking library tracks. It drives me crazy.</p>
<p>Dave! (57:00.674)
I&#39;m sorry.</p>
<p>but bigger and dumber.</p>
<p>Dave! (57:09.291)
Cheers.</p>
<p>Dave! (57:15.35)
But it also, if you go back to, uh, Last House on the Left, it&#39;s got that real whack, like, yadadadada, yadadada! Like, this real kind of, uh, Tin Pan Alley weird country bullshit.</p>
<p>Bryan! (57:22.796)
Yeah.</p>
<p>Bryan! (57:27.468)
Yeah. Well, that one, that seems like that was David Hess&#39;s deal. Yeah. So, yeah, we&#39;re now. We eventually get to Camp Crystal Lake, and the first thing we see is shirtless creep Steve Christie.</p>
<p>Dave! (57:31.231)
Oh, it was.</p>
<p>Dave! (57:43.498)
Now, I gotta... let me just... I&#39;m gonna be a little bit crude for a moment, everybody. Uh... Steve Christie wins the queerest person in this movie.</p>
<p>Bryan! (57:53.292)
No, let me tell you, you know what it is? It&#39;s the kerchief.</p>
<p>Dave! (57:56.338)
Okay, well, all right. I mean, I don&#39;t...</p>
<p>Bryan! (58:01.431)
kids because he&#39;s got the he&#39;s got the little cut off denim shorts</p>
<p>Dave! (58:04.47)
Well, he has got on cut-off booty shorts, he has got on some fuckin&#39; work boots with socks pulled up to his shins, he&#39;s got a fuckin&#39; handkerchief, no shirt, handkerchief, around his neck. He may as well take the fuckin&#39; handkerchief off and tuck it into his back pocket. And that, everybody, is a joke for all the gay men over 40 out there.</p>
<p>Bryan! (58:26.76)
Oh, man. We got a niche audience. But yeah, there&#39;s a real like Tom of Finland quality to this character that yeah.</p>
<p>Dave! (58:28.287)
You&#39;re welcome.</p>
<p>Dave! (58:36.026)
Oh god, it&#39;s ridiculous. And we&#39;re supposed to believe that this is Alice&#39;s, I&#39;m guessing ex-boyfriend.</p>
<p>Bryan! (58:42.496)
it&#39;s okay. So, that&#39;s a question that I have is there. He&#39;s a super creep. That&#39;s the thing is I cannot tell if they have any relationship whatsoever or because the way that she is set up is she&#39;s leaving and her whole thing and he seems like disappointed by this but the</p>
<p>Dave! (58:45.43)
cuz he&#39;s a real fuckin creep, but she seems not interested.</p>
<p>Bryan! (59:05.576)
speaks to her and the way that he touches her is very much in an unwanted advance kind of way. Like, like the reason I guess my whole thing the way that I interpret this is she&#39;s leaving because she cannot stand to be near this guy who&#39;s always touching her hair and like stroking her face and shit.</p>
<p>Dave! (59:11.518)
Oh, but it implies familiarity.</p>
<p>Dave! (59:22.166)
Yeah, I mean, I have a note that says, Ew, Steve, fuck off, you&#39;re a creep.</p>
<p>Bryan! (59:27.352)
Yeah. I it&#39;s I don&#39;t know. No, he has this air of menace about him all the way up to his death scene and he&#39;s a really severe dude for reasons never really made fully clear. But yeah, like.</p>
<p>Dave! (59:41.174)
He does have a bizarre intensity that&#39;s kind of off-putting and it never feels appropriate for the scene ever.</p>
<p>Bryan! (59:47.564)
No, because everybody else is having fun. Like his whole...</p>
<p>Dave! (59:50.506)
Like, he is in the diner later with the greatest character in the entire movie, and he still comes off like a fucking serial killer. Is he supposed to be the red herring?</p>
<p>Bryan! (59:54.536)
Ha ha ha.</p>
<p>Bryan! (59:59.164)
the killer. Uh well, no, because like right after that, he gets <strong><strong> murdered. But but then again, then again, maybe because they, yeah, I know because they do the same thing in </strong></strong> madman when I use his name Max, the kind of head counselor when he kind of vanishes and there&#39;s a whole period where you&#39;re yeah, yeah and there&#39;s a whole period where you&#39;re supposed to be like, oh <em>**</em> is he the killer? So, so maybe because like there are a bunch of just</p>
<p>Dave! (01:00:03.554)
Yeah, but until then, cause he does disappear. We&#39;re getting ahead of ourselves.</p>
<p>Dave! (01:00:15.04)
The one who waxes philosophical non-stop.</p>
<p>Dave! (01:00:25.302)
I mean, I feel like Steve is a killer, but he&#39;s not a budget.</p>
<p>Bryan! (01:00:28.476)
Yeah, there&#39;s oh, there&#39;s bodies already in Crystal on Crystal Lake. Yeah. But yeah, like and so there&#39;s a lot of this is them essentially setting up all the characters. There&#39;s there&#39;s Brenda who goes and sets up the archery range and Ned surprises her by almost shooting her with an arrow. I would like to here&#39;s the thing I would like to think that this was some kind of elaborate special effect that kept the actress safe during the shot.</p>
<p>Dave! (01:00:47.874)
Jesus fucking Christ, Ned. You can&#39;t be shooting arrows at people.</p>
<p>Dave! (01:00:57.451)
No, no.</p>
<p>Bryan! (01:00:58.16)
But I bet that on a budget of half a million dollars, the effect consisted of her standing perilously close to where the arrow was supposed to hit and Tom Savini shooting an arrow from off camera because that&#39;s what they fucking did to these.</p>
<p>Dave! (01:01:10.474)
And let&#39;s be honest, Tom Sabini probably does not know how to fire an arrow.</p>
<p>Bryan! (01:01:14.364)
I don&#39;t know. I, you know, he did all the gun <em>**</em> and maniac, you know, but</p>
<p>Dave! (01:01:20.61)
I mean, if anybody wants to find out, there is an exhaustive history of these movies called, what is it, Camp Crystal Lake Memories. And you can get it in book form, you can watch the documentary, it is extremely long, it is kind of interesting sometimes, it also drags on for fucking ever. And you will be disappointed by everybody.</p>
<p>Bryan! (01:01:26.896)
Camp Crystal Lake memories.</p>
<p>Bryan! (01:01:36.344)
Well, right, because it covers it covers all of the movies. But yeah, so yeah, this is this is, you know, our first like, red herring.</p>
<p>Dave! (01:01:47.45)
And Ned is, apparently Ned, like he is the prototype kind of jokester in horror movies, like that practical joker, like that wasn&#39;t really a thing until this.</p>
<p>Bryan! (01:01:54.97)
Yeah.</p>
<p>He&#39;s the guy. He&#39;s the he. Yeah. So he&#39;s the he&#39;s the guy that kind of Jamie Kennedy eventually comes to embody and scream.</p>
<p>Dave! (01:02:04.554)
I mean, they&#39;re just, they&#39;re tedious, they&#39;re awful, you can&#39;t wait for them to die. I know people like Jamie Kennedy and Scream. I do not. Because he is a thing of the past that needs to stay in the past.</p>
<p>Bryan! (01:02:11.693)
Nah, me either.</p>
<p>Bryan! (01:02:25.164)
the</p>
<p>Dave! (01:02:29.906)
I find this conversation sad and weird.</p>
<p>Bryan! (01:02:33.024)
It is weird because it is entirely one sided and Andy seems very, very friendly and like in a mood to just chat it up with a person who for the what we can tell is not talking back.</p>
<p>Dave! (01:02:44.47)
Yeah, listen, this is my fear about hitchhiking. It&#39;s not the getting murdered part. It&#39;s the awkward small talk part. Honestly, I would rather be murdered than have to make small talk to someone. I don&#39;t even answer my door if someone knocks on it. The fucking building could be on fire and I&#39;d be like, go away, no one&#39;s home.</p>
<p>Bryan! (01:02:59.52)
Oh yeah, no, no. The worst thing in the world is a chatty barber. Nothing worse than, yeah. Like I am stuck in this chair and you wanna know what I do for work.</p>
<p>Dave! (01:03:02.934)
Yeah, I don&#39;t.</p>
<p>Dave! (01:03:07.17)
But Annie is going for it, and she is, I think that she says, I&#39;m like, you know, I guess I always wanted to work with children. And I, you know, I don&#39;t want to minimize the importance of camp counselors in the lives of children, but is she not just going to serve food to them? She&#39;s not teaching remedial English class, like.</p>
<p>Bryan! (01:03:21.48)
she&#39;s a cook. She&#39;s a cook. You know what? This is her dream. How dare you</p>
<p>Dave! (01:03:26.666)
Yeah, she does. She says, When you&#39;ve had a dream as long as I have and I thought, your dream has always been to serve food at a summer camp? Again, I don&#39;t want to judge anybody, but... I mean... Aim higher, Annie. Well, you&#39;re not gonna have a chance to aim higher, but if you did, you should.</p>
<p>Bryan! (01:03:43.372)
No, because this is the thing is the jeep speeds up. It&#39;s flying. It flies past the road to Crystal Lake. And then this is when the tense killer music kind of comes up.</p>
<p>Dave! (01:03:52.542)
And man, do we, but when you say Ted&#39;s killer music, I think what you mean is soap opera music, because this is a real dun kind of moment.</p>
<p>Bryan! (01:03:59.596)
Yeah, yeah. And so we know from this that Annie&#39;s time on this planet is running short. And then she leaps from the movie, from the moving Jeep, which is the right thing to do because this lady ain&#39;t stopping for nobody. She</p>
<p>Dave! (01:04:07.062)
hurls herself out of the car.</p>
<p>Dave! (01:04:12.874)
Now listen everybody, if you want to know what you&#39;re supposed to do in these situations, you are supposed to throw yourself out the car. But just like anything, if you have to fall a great distance from a moving vehicle, you have to land on the softest part of your body. And Annie doesn&#39;t do that.</p>
<p>Bryan! (01:04:29.572)
Nope, she goes right, right for her feet, hurts her ankle, and lea and lurches into the forest while we&#39;re she&#39;s being chased by the killer and POV. She&#39;s eventually found in her throat is cut.</p>
<p>Dave! (01:04:41.622)
Now, what I will say is, so this movie suffers a lot sometimes in 4K because you can see these, it&#39;s all of these scenes, it&#39;s Tom Savini&#39;s hands. It&#39;s always Tom Savini doing the killing.</p>
<p>Bryan! (01:04:50.008)
Yeah. Well, it&#39;s yeah. Again, this is like it&#39;s sleepaway camp where they&#39;re like, well, we can&#39;t we can&#39;t do Angela&#39;s hands. So we&#39;re going to use Ricky&#39;s hands instead.</p>
<p>Dave! (01:04:58.978)
but they do a decent job in this moment because he&#39;s kind of right up on her, but the camera moves in certain ways so as to obscure that it is him. You can tell it&#39;s a larger person, but you don&#39;t get man from it.</p>
<p>Bryan! (01:05:05.21)
Yeah.</p>
<p>Bryan! (01:05:16.024)
right? Right. Um to its credit, this is a fly by night use like movie magic to like if you&#39;ve never, if you&#39;ve never seen this movie killer is at the end. That&#39;s surprising. Uh also because I are, it&#39;s a guy.</p>
<p>Dave! (01:05:28.162)
Yeah, this movie is shot very well.</p>
<p>Bryan! (01:05:44.54)
because up to this point, they&#39;ve all been, you know, guys. It&#39;s always, it&#39;s always guys. It&#39;s always fucking guys. Yep. So, back at camp, the counselors are frolicking, frolicking in the actual lake while the killer watches from the forest.</p>
<p>Dave! (01:05:47.03)
Well, and also, let&#39;s be honest, who kills women? Men. Men kill women. That&#39;s who.</p>
<p>Dave! (01:05:58.098)
Oh lord. What a real fun. Hey, you know what&#39;s a real funny joke? You&#39;re hanging out with your friends and then you pretend that you&#39;re drowning and then when they save you from your pretend drowning, you sexually assault the woman who&#39;s giving you mouth-to-mouth. Oh, it&#39;s an old classic. What a hoot.</p>
<p>Bryan! (01:06:03.383)
of fake drowning?</p>
<p>Bryan! (01:06:15.212)
Yep. I will say this though, as he starts up the ruse, they cut they do with a shot that of the every other camp counselors on the shore. And Kevin Bacon is standing up in it. And all you see is his fucking hog in the shot like it is</p>
<p>Dave! (01:06:30.704)
Oh yeah, no. Kevin Bacon is wearing the Speedo to end all Speedos.</p>
<p>Bryan! (01:06:36.849)
Yep. So, yeah.</p>
<p>Dave! (01:06:37.948)
I feel like they didn&#39;t have a costume budget so much, but I feel like if they did, you would&#39;ve been like, Yeah, but do you have anything smaller?</p>
<p>Bryan! (01:06:45.44)
Yeah, you know, the water really fills it out. I really need these to be tight. Yeah.</p>
<p>Dave! (01:06:49.77)
Because this is, if you, if you&#39;re ladies, men, if you&#39;re interested in Kevin Bacon, this is your movie. Because you&#39;re not just getting, you&#39;re going to get some Kevin Bacon ass a little bit later.</p>
<p>Bryan! (01:06:56.425)
Yep.</p>
<p>Bryan! (01:07:05.408)
the</p>
<p>Dave! (01:07:09.294)
Yeah, yeah. Now look, I generally have imposed to killing animals for anything, for the most part. But I&#39;m cool with this, because this is, in case people don&#39;t know, they actually do kill an actual snake, but I&#39;m fine with it. And you know who&#39;s doing the actual killing of the snake? That&#39;s Bing Crosby&#39;s son.</p>
<p>Bryan! (01:07:15.884)
Oh no, chop the fucking snakes in half. I like I don&#39;t. Yeah.</p>
<p>Bryan! (01:07:26.232)
It&#39;s like what a big Crosby&#39;s son. Yeah. One of the ones who did not accuse him of child abuse. Just one of them. Because it turns out, it turns out he&#39;s got like, he&#39;s got something like six sons or something like that. And then like there&#39;s also some daughters involved. Like this guy reproduced. Yeah.</p>
<p>Dave! (01:07:33.115)
Just just one.</p>
<p>Goddamn, that&#39;s real bleak!</p>
<p>Dave! (01:07:46.222)
Yeah, he seems like he was a cut-out piece of shit.</p>
<p>Bryan! (01:07:49.289)
I mean, they all were, but what do we, this isn&#39;t the Ben Crosby podcast. It could be, though.</p>
<p>Dave! (01:07:53.998)
Oh... well. I mean, I have another... I have a... is it a wig before that? But then after that is the Bing Crosby Podcast. It&#39;s a couple episodes long. That&#39;s about it.</p>
<p>Bryan! (01:08:03.9)
Yeah. Yeah. Yeah. So, uh for some reason, a cop rides up on a motorcycle. I don&#39;t. That is doing some just racist <em>**</em></p>
<p>Dave! (01:08:10.036)
And this cop sucks, but you know what? So does Ned.</p>
<p>Yeah, he&#39;s coming out real hot being racist, with a big headdress on, like a warp on it.</p>
<p>Bryan! (01:08:18.22)
in it. Yeah, like a big feathered war bonnet and he&#39;s but like there&#39;s no setup.</p>
<p>Dave! (01:08:23.698)
And hey, just in case anyone&#39;s interested, there are no tribes on the East Coast that would have warned that. So there&#39;s no reason that should have been there other than racism.</p>
<p>Bryan! (01:08:31.856)
No.</p>
<p>Bryan! (01:08:35.392)
Oh yeah. I mean, I&#39;m sure that they like that there is like some Boy Scout shit that was just laying around that he&#39;s like, oh, I got an idea for this thing because having actually attended Boy Scout camps like I&#39;m sure things are a little different now, but like in the night in the 1980s, yeah, the 1980s, like, you know, the whole Indian thing was they were they were real loose with it. If you get my drift.</p>
<p>Dave! (01:08:43.457)
This is gonna be real funny.</p>
<p>Dave! (01:08:51.854)
I think they are, unfortunately.</p>
<p>Dave! (01:08:59.618)
Yeah, it&#39;s like, hey, what&#39;s funnier than pretending that you&#39;re drowning and then sexually assaulting someone? Racism.</p>
<p>Bryan! (01:09:06.456)
Yeah, he&#39;s and the thing is about this is that it really sticks out to me when I watch it now is there&#39;s no setup. There&#39;s no context. He&#39;s just he&#39;s just doing it apparently by himself for fun. I don&#39;t know.</p>
<p>Dave! (01:09:17.226)
Yeah, well there&#39;s a part later where I said, but this is also where you get the, you can see by the third one, they kind of cross the threshold and they&#39;re like, oh, this personality fucking sucks. With Shelley in the third one, where you&#39;re like, God, Shelley, I can&#39;t wait for you to die.</p>
<p>Bryan! (01:09:31.257)
Yeah.</p>
<p>Bryan! (01:09:34.696)
Yeah. Again, and the thing is, is with part two, the net and that one is almost identical to this guy down to the way he looks. Yeah. Yeah, unfortunately.</p>
<p>Dave! (01:09:45.818)
And he lives. He&#39;s one of the only ones who lives. Incidentally, he is also in my favorite episode of the X-Files. Yeah, he is in Clyde Bruckner&#39;s final repose. Yeah, he&#39;s the killer.</p>
<p>Bryan! (01:09:53.368)
No shit. Which, which favorite episode? Ah, no shit, yeah.</p>
<p>I did not know this. That&#39;s awesome. Yeah. My favorite X Files is also a Darren Morgan episode.</p>
<p>Dave! (01:10:07.342)
because he wrote all the best ones.</p>
<p>Bryan! (01:10:08.816)
the fucking best ones, like four of them. No, it&#39;s you guys, X-Files guys, you&#39;re the best. Just Darren Morgan, you&#39;re just better. It&#39;s Jose Chung&#39;s from outer space, by the way.</p>
<p>Dave! (01:10:11.734)
No disrespect to Kim Manners.</p>
<p>Dave! (01:10:24.456)
Ah, okay, that&#39;s a good solid runner-up for me.</p>
<p>Bryan! (01:10:29.446)
Yeah. So this cop is</p>
<p>Dave! (01:10:30.438)
Also, because you get, uh, wait, isn&#39;t the narrator is Charles Nelson Riley in that one? Fuck yeah.</p>
<p>Bryan! (01:10:34.592)
Yes, it is. Yep. So this cop is basically Joe Friday, and he is convinced that they&#39;re all high on grass hash the weed dig it</p>
<p>Dave! (01:10:47.158)
Yeah, they are just, they are fucking out of their mind on goofballs. All of them. And he is not here, he&#39;s not here for any weirdness, you know?</p>
<p>Bryan! (01:10:50.22)
Yeah. Quail ludes. No, that&#39;s right. Yeah, this was the golden age of the quail. So, yeah, this is another this and it&#39;s basically another bit to set up Ralph as the possible killer because he&#39;s out there because he&#39;s like, yeah, I heard Ralph was out here and just keep an eye out for him. It turns out. He is out there, actually, because.</p>
<p>Dave! (01:10:57.898)
Ugh.</p>
<p>Dave! (01:11:09.694)
And you know, funny thing is, he is out there. He is, for some reason, just fuckin&#39; hangin&#39; out in their pantry, waiting for someone to open the door.</p>
<p>Bryan! (01:11:16.096)
Yep. So he can tell them that they&#39;re all doomed to see. Yep.</p>
<p>Dave! (01:11:21.194)
It&#39;s got a death curse! I feel like I shout, it&#39;s got a death curse, at least once a week. Just apropos about things sometimes.</p>
<p>Bryan! (01:11:28.894)
I gotta I don&#39;t I&#39;m gonna have it fucking tattooed on my hand it&#39;s just looking like just show it to people. Uh we find out that uh the next scene that uh yeah we said Ralph is out there creeping around to terrorize kids about the death curse and then after he</p>
<p>Dave! (01:11:42.442)
Oh, this is the next scene. The next scene is Ned again, and he&#39;s alone, but he&#39;s kind of being really performative. Like he&#39;s walking on a log, and he&#39;s like, kind of got his arms out, he&#39;s singing to himself.</p>
<p>Bryan! (01:11:52.004)
Well, okay. Right, because right before this is where we learn about Chekhov&#39;s emergency generator.</p>
<p>Dave! (01:12:00.082)
Oh, right. And he&#39;s making fun of him for the... Because the guy&#39;s like, Well, don&#39;t worry, I know how to work the generator. He&#39;s like, Oh, I know how to work the generator. I&#39;m like, Okay, that&#39;s not a thing you mock somebody for. That&#39;s a real practical thing to know. Like, he... He is already more valuable than you.</p>
<p>Bryan! (01:12:11.081)
I know, like, I don&#39;t know how to work a generator.</p>
<p>Bryan! (01:12:17.018)
Yeah. I mean, if you need a laugh, man, that&#39;s there. But. I assure you, you&#39;re.</p>
<p>Dave! (01:12:20.522)
Ah, god damn it, am I Ned? Am I Ned in this world?</p>
<p>Well, what do you bring to the world? Well, I write podcasts and I make jokes about things that most people don&#39;t understand.</p>
<p>Bryan! (01:12:30.106)
Ha ha ha.</p>
<p>Yeah, no, you&#39;re not that I assure you this. So yeah, now we&#39;re out there. We&#39;re out wandering around among the cabins. Ned spots somebody out where nobody should be and he goes to investigate. And there&#39;s something about this scene that there&#39;s nothing overtly scary about it, but the way that you don&#39;t really see the person, but you catch the movement and you recognize that it&#39;s somebody who&#39;s trying to avoid detection, there&#39;s a little creep. There&#39;s a little creep in there.</p>
<p>Dave! (01:12:58.926)
And they will do that again a couple of times and every single time it is really effective. I think it is really, really great. It&#39;s probably one of the best things about this movie because this is a movie that is so unsubtle in every way. And yet every now and then there&#39;s a moment where you&#39;re like, oh, okay.</p>
<p>Bryan! (01:13:15.404)
Yeah. But like and that&#39;s one of them. I love it because it&#39;s just you don&#39;t see it. It&#39;s a person who is not supposed to be there which is a thing that I kind of talk about sometimes in movies that actually scare me. It&#39;s the uncanny. It&#39;s people behaving strangely. It&#39;s shit where there shouldn&#39;t be shit that kind of tends to get under my skin.</p>
<p>Dave! (01:13:34.77)
It&#39;s the fucking moment in the closet in Black Christmas. Everybody, Black Christmas is coming up in December. It is going to be, it&#39;s basically gonna be me that Jonah Hill meme where he&#39;s waving his hands around me a little excited. It&#39;s gonna be me doing that for three hours.</p>
<p>Bryan! (01:13:49.386)
Yeah, but yeah, like that&#39;s again, that&#39;s why I like Black Christmas gets to me. Like there&#39;s so many moments in that movie where there is somebody who you never see who is behaving.</p>
<p>Dave! (01:13:59.326)
It is, it&#39;s the uncanny valley. Something is just a little bit off, but not so much that you&#39;d notice. You just sorta catch it. I will say, we missed a moment. We missed Ralph&#39;s second exit from the film again, where he runs out of that cabin. It&#39;s a real exit stage left moment. Like, he just fuckin&#39; shimmies out the door and just down the back. It really is fantastic.</p>
<p>Bryan! (01:14:07.874)
God, I cannot wait to fucking talk about those phone calls.</p>
<p>Dave! (01:14:37.186)
Yeah, this is a serious rap on Ned.</p>
<p>Bryan! (01:14:48.33)
the</p>
<p>Dave! (01:14:53.791)
Yeah, man, listen, Monopoly takes days to play. I remember when we lived, the first house we lived in Northampton, we would play Monopoly and that shit would go on for hours. And I, as a kid, as a kid, I loved it. And it was probably just because like, we&#39;re all hanging out in the basement playing this game that goes on forever. If you were to be like, hey, do you wanna play Monopoly? And they&#39;re like, get the fuck away from me, no!</p>
<p>Bryan! (01:15:06.908)
It takes fucking hours.</p>
<p>Bryan! (01:15:13.305)
Yeah.</p>
<p>Bryan! (01:15:18.592)
I know. I know. We had no ticket to ride these days. You know, like these were these were pre-ticket to ride hours.</p>
<p>Dave! (01:15:24.322)
Like, I have to take an edible to watch TV at night. Like, I can&#39;t sit around and play Monopoly with you.</p>
<p>And also, like, strip monopoly, just that sounds even worse!</p>
<p>Bryan! (01:15:37.084)
Oh, God, I know, because there&#39;s all these time where you&#39;re just cold and naked and nobody&#39;s doing nothing. Oh, so Jack and Marcy are having sex and then the camera pans up.</p>
<p>Dave! (01:15:42.702)
Nobody&#39;s doing anything.</p>
<p>Dave! (01:15:47.554)
Though I will say at one point, one of them goes, why don&#39;t you see if Marcy left any of that grass? And I thought, God, I love the term grass.</p>
<p>Bryan! (01:15:52.62)
Yeah. She says, what is I know? What she says? Uh oh, I won&#39;t pass go without a glow.</p>
<p>Dave! (01:16:00.286)
Ugh, I fucking, I love a good euphemism, and grass I think might be one of my favorites.</p>
<p>Bryan! (01:16:05.396)
Grass is one of my favorites. Yeah, it&#39;s one that I wish would come back. But two people are too fucking up and up in arms about cannabis these days. Now, anyways, yeah, the camera pans up and we learn of Ned&#39;s horrific demise. This road has been.</p>
<p>Dave! (01:16:15.875)
Yeah.</p>
<p>Dave! (01:16:21.942)
Now, listen, I gotta be honest. Maybe it&#39;s the 4K of it all, but if I walked into a room and stepped up to a bunk, because they are on a bunk bed, Jack and Marcy, Jack and Marcy, is that his name? So they&#39;re on a bunk bed, they&#39;re in a lower bunk. If you walked up, you would notice a dead body on the upper bunk. It is right at eye level. So, I&#39;m gonna go ahead and go ahead and go ahead and go ahead</p>
<p>Bryan! (01:16:32.804)
Yeah. Jack and Marcy.</p>
<p>Bryan! (01:16:40.24)
you. Yeah, cuz it&#39;s not it&#39;s not it&#39;s not as though he was concealed or anything as we see him. He&#39;s just laying there. So, yeah. Yeah. I mean, I know I, you know, the that part of is not withstanding like, you know, there it is. It&#39;s we gotta we gotta kinda roll with it.</p>
<p>Dave! (01:16:46.431)
No!</p>
<p>Dave! (01:16:59.766)
Yeah, there&#39;s a lot of suspension of disbelief in this movie. From here on out.</p>
<p>Bryan! (01:17:03.776)
Yeah, yeah. Everybody&#39;s now fully partying down. A lot of beer going around, they&#39;re smoking weed. Yep, smoking grass.</p>
<p>Dave! (01:17:08.67)
Yeah. Yeah, this is very 80s partying. Yeah, this is very like it&#39;s like chopping mall party where it&#39;s just like this is this is what you think a party is. I mean, I guess it kind of is what a party is.</p>
<p>Bryan! (01:17:16.225)
Hahaha.</p>
<p>I mean, this is a not there&#39;s not much else to do at this summer camp. So they&#39;re making they&#39;re making do. So now out in Jack and Marcy&#39;s cabin Marcy&#39;s off using the restroom. Jack is alone. The blood</p>
<p>Dave! (01:17:34.438)
Oh wait, before she goes, she leaves to go to the bathroom. She turns this, so they, this is post-coital. And she turns to him and says, You&#39;re so fine.</p>
<p>Bryan! (01:17:41.338)
Yep.</p>
<p>Bryan! (01:17:44.572)
I know. She also says, so they&#39;re they&#39;re kind of like big spoon, little spoon in this in this scene and she says you&#39;re laying on my bladder and I&#39;m like no, he&#39;s not. He&#39;s <em>**</em> behind you. But yeah, she&#39;s just good. She&#39;s just reading the lines. I gotta I&#39;ll give her a break but yeah, she goes off to use the bathroom jacks alone. Blood drips on him from above but before he can even do anything about it at arm reach.</p>
<p>Dave! (01:17:58.388)
No. Well, she might be very confused.</p>
<p>Dave! (01:18:11.562)
Now, I&#39;m gonna stop you right here, because I&#39;m gonna say this is a controversial moment. There is a scene in Bay of Blood that is very, very similar to this. And I find it hard to believe that either Victor Miller or Sean Cunningham saw Bay of Blood.</p>
<p>Bryan! (01:18:21.646)
Ah</p>
<p>Don&#39;t wait.</p>
<p>Bryan! (01:18:29.948)
Okay, no, you&#39;re actually ahead of yourself. The Bay of Blood scene is where they get staked together. That happens in part two.</p>
<p>Dave! (01:18:42.946)
Really? I thought the arrow thing isn&#39;t there.</p>
<p>Bryan! (01:18:46.62)
the movie. No, no, it&#39;s the which that kill scene was taken directly from Bay of Blood but it&#39;s in it&#39;s in Friday two. It is a hell of a movie. Uh so he gets, yeah, so the arm reaches around, pulls him down, and he gets an arrow like rammed through his neck from below. It&#39;s probably, it&#39;s for my money, it&#39;s the best kill scene in the movie. It&#39;s so good.</p>
<p>Dave! (01:18:54.139)
Well, pay him once I help him.</p>
<p>Dave! (01:19:09.226)
Yeah, it is the most iconic kill scene in the movie. I will say it is probably top five kills in the whole series. It is not the number one. We all know what the number one is.</p>
<p>Bryan! (01:19:22.7)
I&#39;m interested. What&#39;s your, the it&#39;s the sleeping bag. you surprised me sometimes and</p>
<p>Dave! (01:19:24.574)
It&#39;s the sleeping bag and you know it is.</p>
<p>Dave! (01:19:31.562)
I mean, I would say close second is demon in the outhouse, but it&#39;s the sleeping.</p>
<p>Bryan! (01:19:36.316)
the bathroom. Yeah. So, um yeah, now Marcy is out in the bathroom. The killer comes in behind her. She thinks she hears somebody moving around but she dismisses it and I do believe that this is probably the first the first occurrence of the uh hey, come on out. I know it&#39;s you. Yeah, cuz</p>
<p>Dave! (01:19:58.994)
I love, I think this fucking scene is awesome.</p>
<p>Bryan! (01:20:03.512)
she dismisses it and it&#39;s not like she gets killed. They kind of drag this one out a little bit. She&#39;s</p>
<p>Dave! (01:20:08.37)
But it is real tense from the moment she&#39;s fucking around with the water and she does that kind of like, she does what I think is a great Hepburn impression. Yeah. And then, but they like, there&#39;s just a lot of weird, they really drag it out and I think it is effectively tense in a movie that is not always, well, I&#39;ve already said it is unsubtle in every way.</p>
<p>Bryan! (01:20:18.669)
It is it&#39;s Catherine Hepburn from the Rainmaker.</p>
<p>Dave! (01:20:37.438)
And I think when she gets killed, it looks great. Like I think the application looks great. The makeup&#39;s great. It all is fantastic.</p>
<p>Bryan! (01:20:37.461)
I think I&#39;m gonna say</p>
<p>Bryan! (01:20:44.748)
Yep. Lizzie, you&#39;ll always be playing. I bet you anything that that&#39;s like, you know, like she&#39;s got, she&#39;s got an impression. And she&#39;s got like this. And then they&#39;re like, and they&#39;re like, give us a monologue. And she always does Catherine Hepburn in the Rainmaker. So again, she hears somebody moving around and she goes back to check in the showers and is rewarded for her curiosity with an axe to the face.</p>
<p>Dave! (01:20:53.654)
Yeah, they&#39;re like, just do a bit, just do something.</p>
<p>Dave! (01:21:08.35)
And it looks, I mean, again, this is, when I was watching this, I thought, this is why Tom Savini was the best. Like, it looks fucking great.</p>
<p>Bryan! (01:21:18.968)
Yep. It&#39;s a it&#39;s a hell of a kill. And nowadays, it&#39;s kind of hard to it&#39;s kind of hard to know which one I&#39;m watching, because I always sort of assume that I&#39;m just watching the unrated version. But for a long, long time, the only version of this movie that you ever got was the R rated version. And I don&#39;t remember which parts were trimmed. I guarantee it was just like seconds here and there.</p>
<p>Dave! (01:21:41.934)
They&#39;re, in the first one it is, it&#39;s the second one that was super heavily edited. So I have the box set and they both have the unrated, but this one I think they just cut little pieces here and there. It&#39;s part two that has like big chunks taken out.</p>
<p>Bryan! (01:21:58.276)
Yeah. So yeah, back at the Monopoly game, Brenda splits off declaring that her cabin windows are left open, which is a cardinal foul in a slasher movie. And after a bit of bullshit, establishing that Steve has been away being a creep elsewhere in New Jersey. Brenda returns to the restroom. This is the scene that you were talking about with the diner. And</p>
<p>Dave! (01:22:18.39)
You&#39;re not gonna skip across the diner, are you? Okay, whew.</p>
<p>Bryan! (01:22:20.76)
No, no, do the do the diner because he&#39;s there. It&#39;s late. It&#39;s raining and the lady is</p>
<p>Dave! (01:22:27.79)
Steve&#39;s a local and Sandy, Sandy is happy to see Steve. She&#39;s working the diner, it&#39;s the late shift. Now, look, is Sandy a contender for Is It a Wig? Absolutely. She is fantastic. Now, Sandy is, yeah, she is, she is, if like, this woman, her name is Sally Ann Golden.</p>
<p>Bryan! (01:22:44.248)
Yeah. He&#39;s like, Hey, what do I, what do I owe you Sandy? A night on the town, honey.</p>
<p>Dave! (01:22:53.886)
She died like a year or two after this movie came out. She&#39;s only been in like a handful of things, one of which is Alice, Sweet Alice. Yeah. And she, if someone was like, hey, you&#39;re casting a diner scene in a horror movie, who&#39;s behind the counter? Go. My immediate answer, Sally Ann Golden. She&#39;s fantastic. Now, as Steve gets up, about to pay his bill.</p>
<p>Bryan! (01:23:01.358)
Oh no shit.</p>
<p>Bryan! (01:23:11.325)
Yep.</p>
<p>Dave! (01:23:18.242)
gives her the tip, and honestly, the tip is less than a dollar, so let&#39;s not get too excited, Sandy. But Steve goes out the door and she yells, Drive carefully! And then she looks longingly for Lord Lee in his direction, and they just kind of linger on her face. I swear to God, Sandy&#39;s the best part of this fucking-</p>
<p>Bryan! (01:23:29.459)
Ha ha ha.</p>
<p>Dave! (01:23:40.998)
Ah, and then I have to say, is Steve driving the same Jeep as the killer? Because I think that is also part of the red herring.</p>
<p>Bryan! (01:23:42.14)
us.</p>
<p>Bryan! (01:23:49.036)
right. I don&#39;t think so because at the end because we see because we see I mean it I&#39;m going to say it&#39;s probably the same like prop car because we see as he&#39;s driving back the Jeep breaks down and then Mrs. Voorhees shows up in another Jeep that I assume is just</p>
<p>Dave! (01:23:51.582)
I&#39;m pretty sure it&#39;s the same car. It&#39;s the same make and model, at the very least.</p>
<p>Dave! (01:24:10.494)
I&#39;m pretty sure it&#39;s the same. I think it&#39;s the same.</p>
<p>Bryan! (01:24:13.756)
Because she&#39;s driving the Jeep earlier in the movie. Yeah. So it&#39;s, yeah.</p>
<p>Dave! (01:24:16.726)
Yes. Oh yeah, that wouldn&#39;t make sense. So I think it&#39;s just a weird continuity.</p>
<p>Bryan! (01:24:23.132)
car. Yeah. Or that&#39;s honestly, it&#39;s probably that like they just wanted to reuse a you know, a car. So, back at the Monopoly game, Brenda Split. Oh yeah, I said that already. I&#39;ll cut that out.</p>
<p>Dave! (01:24:24.182)
Like, they were like, we only have one other vehicle. Let&#39;s just shoot this one at night.</p>
<p>Dave! (01:24:42.838)
Well now Brenda has decided to retire to bed and I thought, you know, for a bunch of sexy teens, Brenda&#39;s rocking some real serious little house in the prairie pajamas.</p>
<p>Bryan! (01:24:50.748)
like, oh yeah, that&#39;s like that thing is like, it is a nightgown that is neck to ankles, opaque as the night is black. Um it&#39;s very funny cuz like, yeah, cuz just in the scene prior, like, everybody&#39;s hanging out in their underwear basically and she is gonna be as chased as possible. Uh but yeah, she returns to the restroom where Marcy was murdered in order to clean up and so,</p>
<p>Dave! (01:25:00.03)
Yeah, it is, it is real homespun shit.</p>
<p>Bryan! (01:25:18.244)
they&#39;re now we&#39;re gonna do this whole thing where they kind of cut back and forth between Brenda and Steve on his way back because like his car breaks down, he gets picked up by the cops. She goes to clean up. She gets into bed. She does some reading and they cut back and forth between these two in a way that I think it really manages the economy of time and also it ratchets up the tension a little bit because like as much as you&#39;re like this isn&#39;t</p>
<p>Dave! (01:25:43.074)
because this is another, her scene is another one where you&#39;re like, this is very good, it is very tense. And the movement back and forth between these two, not set pieces, but just these kind of two moments is really good, it&#39;s quick.</p>
<p>Bryan! (01:25:57.02)
Because the setup is it&#39;s who&#39;s going to get it next. Because you know somebody&#39;s going to get it. And for all of your talk of like there&#39;s just not subtle, it isn&#39;t. But when it&#39;s trying to, you know.</p>
<p>Dave! (01:26:10.326)
Well that&#39;s what I&#39;m saying, is like when it is, it is so fucking good.</p>
<p>Bryan! (01:26:13.888)
And this is one of those, this is definitely one of those seen.</p>
<p>Dave! (01:26:15.606)
There is a scene in this moment with her in the bathroom that I think is the best scene in the entire.</p>
<p>Bryan! (01:26:21.036)
Yeah, so this movie is obviously aping all of Halloween&#39;s moves, but the victim pool in Friday is pretty much twice that of Halloween. So they have to do a couple of things. And it&#39;s really the first half of the movie, like we are about a half. We&#39;re about at the 50 percent mark at this point. The first half of the movie is basically people fixing a camp and having fun. And I think that they do a really good job of sort of like establishing that. And I&#39;ll.</p>
<p>I like a lot of these characters. Like I like Brenda, I like Marcy and Jack, like everybody except Ned to me. I fucking... Yeah, yeah.</p>
<p>Dave! (01:26:55.054)
I mean, they&#39;re fine, they&#39;re just kind of forgettable. Like, I couldn&#39;t tell you who&#39;s who except for Kevin Bacon.</p>
<p>Bryan! (01:27:02.04)
Yeah, and I have to look up their names and shit. Like, I&#39;m constantly forgetting who the sort of main boy is. I think his name is Bill. But yeah.</p>
<p>Dave! (01:27:08.758)
Yeah. Whereas in, you know, in Halloween, I can tell you exactly everyone&#39;s name and I can fucking give you an entire biographical rundown of them because I&#39;m obsessed with all of them.</p>
<p>Bryan! (01:27:16.536)
Yeah. But the back half of this the back half of this movie is where all the murders happen. It has to juggle all of these people until it&#39;s time to kill them and it does it fairly well. Um but yeah, so like we said, they&#39;re bouncing back and forth between these. You know, somebody&#39;s about to get off but you don&#39;t know who. Spoiler.</p>
<p>Dave! (01:27:34.194)
I will say though, I think it probably has a lot to do with the fact that Victor Miller was primarily a soap opera writer. So you learn how to, you know, you have a finite amount of time. Yeah.</p>
<p>Bryan! (01:27:41.204)
Yeah. You balance a shitload of characters. Yeah. And yeah, like honestly, like if you have to kill off like, you know, way more people than the movie that came before you, you get a guy who&#39;s like managing a show that has like two dozen main characters and like five. Yeah. Anyway, spoiler alert, Brenda is the next one to get killed. And so she&#39;s this part. I love this because her death is</p>
<p>Dave! (01:27:59.766)
And you gotta get real fucking creative.</p>
<p>Dave! (01:28:05.313)
Ah.</p>
<p>Bryan! (01:28:11.008)
you don&#39;t see it but the lead up to it is fucking crazy because she&#39;s lured out into the rain by somebody calling for help.</p>
<p>Dave! (01:28:19.17)
But first, before that even happens, you see she sort of looks over, she hears that, I think it might be the first time she hears something, but she looks over at the showers and they have, they&#39;re basically just the showers that have the pull curtain across them. And she looks and you see a hand just sort of creep a little bit around. It&#39;s not a full arm or anything. It&#39;s just a person&#39;s hand, kind of touch the outside of it and then pull back into it. And that scene is very, very creepy.</p>
<p>Bryan! (01:28:32.58)
Yeah.</p>
<p>Bryan! (01:28:43.898)
Yeah.</p>
<p>Yeah. I.</p>
<p>Dave! (01:28:47.966)
And it&#39;s weird because I would say at best the direction in this film is competent. That moment is fucking brilliant.</p>
<p>Bryan! (01:28:55.884)
It&#39;s outstanding and it&#39;s something that I think like it&#39;s like it&#39;s a subtle thing that just If you if you play your fucking cards, right and you&#39;re subtle when you need to be subtle You can be actually scary because i&#39;ll tell you what there&#39;s not a lot of horror movies that scare me anymore</p>
<p>Dave! (01:29:12.49)
No, I can&#39;t think of a single movie that scares me, that has ever scared me.</p>
<p>Bryan! (01:29:15.284)
I can&#39;t remember the last time. I cannot remember the last time. There have been some, but I cannot remember the last time that I was like chilled to the fucking bone and carried it with me. And there are a couple of moments in here that like I kind of like kind of make me go like, oh, you know, that&#39;s one of them. So things are are.</p>
<p>are starting to get out of control. Brenda is lured to the archery range and like unseen, you know, hands the in the jello style, like, turn on the lights. The last we ever see of her is essentially like trying to see past the like bank of fucking lights in front of her.</p>
<p>Dave! (01:29:55.51)
And then we cut back to the police car where there is a conversation that goes something like this. Old Ralph was out our way today. His wife was a nervous wreck until we got him home. I&#39;m sorry? His wife?</p>
<p>Bryan! (01:30:07.204)
Yeah, his wife? What? I had never noticed this detail until a very recent viewing of the movie. What must it be like with this guy just? No, he&#39;s a soothsayer. She does all the work and he just predicts who&#39;s gonna die next.</p>
<p>Dave! (01:30:15.586)
What is their home life like? Does he do laundry? Who makes dinner?</p>
<p>Dave! (01:30:29.87)
God, what a job!</p>
<p>Bryan! (01:30:31.396)
his wife? Yeah.</p>
<p>Dave! (01:30:32.95)
Yeah, his wife, Crazy Ralph&#39;s wife.</p>
<p>Bryan! (01:30:36.516)
Yeah, Bill and right before this, Bill and Alice finally like go and check on Brenda and because Alice thinks that she heard a scream and they find a bloody axe in her in her bed and then everybody else&#39;s bunks are empty. They decide to call for help and having broken into the office, they find that the telephone&#39;s dead and the truck won&#39;t start. So yeah, this is what Steve is picked up by the cop. The cop has to respond to an accident.</p>
<p>Dave! (01:31:04.054)
Yeah, cause he gets a call and it&#39;s a head-on collision, they need the jaws of life, and he says, Alright, I&#39;ll be there in 15 minutes, and I thought, what is this, Detroit? In 15 minutes, they&#39;re gonna be dead by the time you get there, take your fucking time!</p>
<p>Bryan! (01:31:12.9)
You know what it is? You know what it is there&#39;s it&#39;s country roads because like whenever they ask like, hey, where&#39;s Camp Crystal Lake? They&#39;re like, ah, it&#39;s like 20 miles from here. You see another sign that&#39;s like Camp Crystal Lake, 12 miles. So like getting around here, like these are like, you&#39;re there where it turns out where this movie takes place, or was shot rather than it&#39;s sort of like, I guess you can kind of say takes place there as well. You&#39;re there right on the fucking edge of like</p>
<p>Pennsylvania coal country. So like everything is really, really rural out here. So just getting around is a pain in the ass. So when he says it&#39;ll take 15 minutes, like I fucking believe it. But, uh, yeah.</p>
<p>Dave! (01:31:54.87)
Alright, well, I mean I don&#39;t want to sound all ACAB, but 15 minutes in a crisis...</p>
<p>Bryan! (01:32:01.387)
the</p>
<p>Dave! (01:32:10.678)
Yeah, he says, it&#39;s letting up, so you can walk. I have to, you know.</p>
<p>Bryan! (01:32:15.256)
Yeah, so he&#39;s on his way back into camp and he&#39;s stopped by somebody with a flashlight and he regards them as if he knows them. Yep. And then this person kills him and nothing of value was lost.</p>
<p>Dave! (01:32:21.59)
Oh, it&#39;s you.</p>
<p>Dave! (01:32:26.346)
No, it&#39;s fine, he sucks a lot.</p>
<p>Bryan! (01:32:30.603)
So with only two left to kill, the killer shuts down the generator. She is really making tracks around this camp. Like this lady is everywhere.</p>
<p>Dave! (01:32:38.442)
Yeah, no, this is where Suspension of Disbelief really kicks in, because there&#39;s a lot of things that, you know, she&#39;s not an old woman. She is an older woman.</p>
<p>Bryan! (01:32:42.029)
Yeah.</p>
<p>Bryan! (01:32:48.056)
Yeah, it&#39;s just like definitely like firmly middle age. But yeah, she plunges the camp into darkness. Of course, Bill goes to check on it by himself.</p>
<p>Dave! (01:32:55.198)
Meanwhile, Alice cozies up on an absolutely filthy couch. And...</p>
<p>Bryan! (01:33:01.004)
Yeah. Oh, man. I, you know what? Having been to these places, I could smell that couch, you know?</p>
<p>Dave! (01:33:06.038)
Well, I&#39;ll tell you what, I&#39;m looking at that and I thought, that looks like the couch in the office at Bull Moose Music. You didn&#39;t want to sit on it, let alone snuggle up on it. And for those of you who don&#39;t know, Bull Moose Music is a record store that we both worked at. I worked there for a very long time. It had this disgusting like couch that like, you know everybody had sex on this thing. It&#39;s like anytime someone would sit on it, I&#39;d be like, I don&#39;t think, don&#39;t, let me get you a chair.</p>
<p>Bryan! (01:33:34.596)
Oh yeah, I know it smell crazy in there. It&#39;s uh</p>
<p>Dave! (01:33:37.418)
Yeah. And this couch is just fucking gross. And that&#39;s the first thing I thought when I looked at it.</p>
<p>Bryan! (01:33:43.213)
Yep. So, yeah, now after a time, Alice goes to look for him and she finds his dead body all jacked up at the generator. He&#39;s got like arrows stuck in him and shit and his throat&#39;s cut.</p>
<p>Dave! (01:33:53.33)
And I mean, this is again, this is that suspension display, like, you know, she&#39;s an older woman. Did she hoist him up and pin him to the door with arrows?</p>
<p>Bryan! (01:33:58.608)
did she did she lift him up? I know but yeah but now Alice turns and she does now this is like you can tell that even as they&#39;re just aping these this movie moves from Halloween, they kind of studied it and I think they had a better understanding of it than a lot of the other movies that sort of exploited her.</p>
<p>Dave! (01:34:18.686)
And I think they also wanna have a little more fun with it. Halloween is a pretty serious.</p>
<p>Bryan! (01:34:21.94)
Yeah, cuz yeah, cuz but she does all sorts of like smart shit in order to kind of like assure her safety in here like gathers up weapons. She fucking does this thing where she like</p>
<p>Dave! (01:34:33.246)
Yeah, I mean, it&#39;s a little too, I think she&#39;s a little too composed. Like, I mean, she&#39;s just, she freaks out when she finds Bill&#39;s body pinned to the door with arrows. And, you know, it&#39;s not like you found someone who has died. You found someone who was very brutally killed, and you have every reason to believe that everyone else is also dead. And she freaks out for a minute, but then she&#39;s just like, all right, Alice, get your shit together.</p>
<p>Bryan! (01:34:38.109)
Yeah.</p>
<p>Bryan! (01:35:02.584)
Yeah, yeah, but. But then the killer throws Brenda&#39;s jacked up body through the window.</p>
<p>Dave! (01:35:03.226)
It&#39;s you against the fucking world now!</p>
<p>Dave! (01:35:09.254)
And I will tell you what, I actually really like this moment because throwing bodies through the window becomes kind of a hallmark of this series. And she doesn&#39;t throw Brenda through the window so much as she kind of dumps Brenda&#39;s body through the window. Like, she kind of took a bit of a running start and firemen kind of hurled her through the window. Yeah, she just sort of tumbles through. She&#39;s not chucked through like a fucking bay window.</p>
<p>Bryan! (01:35:15.925)
Yeah, yeah, they-</p>
<p>Bryan! (01:35:22.283)
Ha ha.</p>
<p>Bryan! (01:35:28.271)
like hoist hurled her through the window. Yeah.</p>
<p>Bryan! (01:35:34.968)
Yeah, yeah, because by part four, like everybody&#39;s either jumping through windows or being thrown through windows down to the fucking dog. Like, it&#39;s crazy how much of a like a trope that becomes.</p>
<p>Dave! (01:35:44.982)
And that&#39;s fine because Kane Hodder is a gigantic man who could probably throw someone through a window. I&#39;m gonna say, what&#39;s her face? Yeah, she seems like at best maybe she could dump a body.</p>
<p>Bryan! (01:35:49.184)
Yeah. Yep.</p>
<p>Betsy Palmer.</p>
<p>Bryan! (01:35:59.776)
Yeah. So yeah. And just as all seems lost, a Jeep drives in. And Alice runs out thinking that it&#39;s Steve, but it&#39;s not.</p>
<p>Dave! (01:36:01.352)
And she does.</p>
<p>Dave! (01:36:11.118)
Well, I&#39;ll tell you, Alice starts to run out, and this moment really resonated with me. I&#39;m a person who gets frustrated very easily, and Alice goes to run outside and her jacket gets caught on the stove. And I was like, I would lose my shit if that happened. Like, Alice, maybe she&#39;s real composed. I would be like, fuck it, I&#39;m done. I&#39;m just gonna kill myself in here.</p>
<p>Bryan! (01:36:22.736)
Ha ha ha!</p>
<p>Bryan! (01:36:35.06)
Yeah, but anyways, it turns out that it&#39;s Mrs. For he&#39;s who&#39;s a friend of the Christy&#39;s or so she&#39;s or so she says, and there&#39;s this moment where you&#39;re supposed to be like, Oh, thank God, she&#39;s like, she&#39;s just help. But Alice</p>
<p>Dave! (01:36:39.699)
Surprise!</p>
<p>Dave! (01:36:46.262)
Because she, I will say, she rea- panel of orioles really commits to the bit where she&#39;s just like, what a killer, you must show me where, this is terrible!</p>
<p>Bryan! (01:36:53.084)
the body. Yeah. And so she freaks out, shows her all the bodies. Mrs. Voorhees starts telling her a story instead about a about a boy who drowned in the lake some years ago.</p>
<p>Dave! (01:37:00.85)
Oh, this scene is so fucking great.</p>
<p>Dave! (01:37:06.246)
It&#39;s like she was like, I know that this wasn&#39;t written for a drag queen, but someday there will be drag queens who will want to do this. Because this is such a hammy performance. The counselors weren&#39;t paying attention. They were making love!</p>
<p>Bryan! (01:37:15.214)
Yeah.</p>
<p>Oh, they were. They were having filthy sex, and then she gets even weirder. And it turns out she&#39;s the killer, which is real wild.</p>
<p>Dave! (01:37:28.726)
Because this is in a turn of real fucking soap opera writing. She like literally turns her head and sort of stares off into the distance and flashes back to a memory that she wasn&#39;t there to see so she couldn&#39;t possibly have a memory of it and yet there it is playing out in her head.</p>
<p>Bryan! (01:37:39.117)
Oh yeah!</p>
<p>Bryan! (01:37:47.172)
Well, let&#39;s say this, that because she&#39;s so fucking deranged, she has just constructed this memory.</p>
<p>Dave! (01:37:55.03)
But it is a real, like, she really, like, she looks off into the distance and you can tell it&#39;s like that, like, and then, flashback moment. It&#39;s fantastic.</p>
<p>Bryan! (01:38:05.306)
Yep. To Jason. All mutated and shit drowning.</p>
<p>Dave! (01:38:09.75)
You gotta give it up to soap opera people, man. They fucking kill it every time.</p>
<p>Bryan! (01:38:13.687)
They do it and they do it well, yeah.</p>
<p>Dave! (01:38:15.81)
Like, do you need an actor? Do you need someone to paint your house? Do you need someone to fix your car? Soap opera people can do it. They can do everything.</p>
<p>Bryan! (01:38:21.18)
they&#39;re going to do it. Yeah. Yeah. So, yeah. Now, they god, this made forever. It&#39;s brutal. Yeah. And I don&#39;t know. taking notes and <em>**</em> but like because any other, any other</p>
<p>Dave! (01:38:24.701)
Mm-hmm.</p>
<p>Dave! (01:38:29.814)
This fight goes on forever.</p>
<p>And it is brutal.</p>
<p>Dave! (01:38:43.286)
Yeah, there&#39;s a point where I&#39;m gonna start looking at my phone. I mean, I&#39;ve seen this movie so many fucking times. I know it beat for beat, but like, it really drags on. And Pamela Voorhees, man, she takes a beating in this scene. Apparently though, she was really hitting Adrian King.</p>
<p>Bryan! (01:38:49.901)
Yeah.</p>
<p>Bryan! (01:38:55.768)
Yeah, apparently. Yeah, I was gonna say so when they when they rehearsed this, they&#39;re you know, they fight and so she&#39;s supposed to like slap her around a bit and apparently this is what they did on the stage. She&#39;s like when you do this on the stage, you actually hit a person. I don&#39;t think that&#39;s true. I don&#39;t know if that&#39;s true.</p>
<p>Dave! (01:39:15.214)
I don&#39;t think you do. I actually know how you choreograph fights like that, and that is not, you don&#39;t actually hit each other, but she really, she was like, just hit me.</p>
<p>Bryan! (01:39:21.328)
Ha ha.</p>
<p>Bryan! (01:39:26.746)
Yeah. And and of course, Adrian King is like freaking out. Like what is happening? And she started crying and shit. And Cunningham had to take her side and be like, no, no. This is the movies. We&#39;ll do it in post, like just make it look like you&#39;re doing it.</p>
<p>Dave! (01:39:39.106)
Yeah, don&#39;t worry, we&#39;ll have Tom Savini jump in there when the time&#39;s right.</p>
<p>Bryan! (01:39:42.756)
Yeah. So yeah, like this is this is the thing that they run along the way. She finds all of the bodies of her friends in a big in the big tableau that like Halloween setup. Mrs.</p>
<p>Dave! (01:39:54.994)
It&#39;s Halloween, yeah. And it&#39;s much less effective. I mean, it&#39;s not that effective on Halloween either, but it&#39;s much less effective in this.</p>
<p>Bryan! (01:40:03.02)
No, but literally everybody fucking does it from here on out. So.</p>
<p>Dave! (01:40:06.442)
Yep. Oh no, this movie is a template. This sets the template for everything. As much as people are like, well, Halloween was the first, like, no, this was the first. Halloween is a good movie.</p>
<p>Bryan! (01:40:15.116)
Yeah, yeah, Halloween was the one everybody wanted to do, but this is the one that everybody was like, oh shit, like that&#39;s how you make a million dollars, you know, for like nothing.</p>
<p>Dave! (01:40:23.606)
Because you&#39;re literally get like the beginning right after this, year after year, you get multiple knockoffs. And some of them are great. And are we gonna talk about them? We&#39;re gonna talk about them. Happy birthday to me. Fuck yeah, it&#39;s coming.</p>
<p>Bryan! (01:40:30.816)
Oh yeah, dude, the burning madman sleepaway camp. Fuck yeah, we are. Yeah, so Mrs. Voorhees is now like fully in the throes of like madness. She&#39;s talking to herself in the voice of Jason, which I love. Yeah, because it&#39;s not just her. She&#39;s doing it in like a little boy&#39;s voice. It&#39;s so great. And that&#39;s and that&#39;s where the musical motif comes from.</p>
<p>Dave! (01:40:48.242)
Indeed, it is over the top, it is perfect.</p>
<p>Bryan! (01:41:00.804)
That&#39;s where it&#39;s taken from. But it&#39;s not her, it was actually Manfredini like doing it into a, it was all him, but like he took it from this scene, because he loved it. Apparently there&#39;s another movie that did something like that, that he was like, ooh, I&#39;m stealing it, but the specifics are escaping me right now.</p>
<p>Dave! (01:41:06.198)
Yes.</p>
<p>Dave! (01:41:18.146)
Look, this man has done over 200 film scores. He has stolen more than just this.</p>
<p>Bryan! (01:41:23.464)
he is he&#39;s so fucking good but I&#39;m gonna say this like honestly this is I this is his number one innovation even but every yeah everybody but like here&#39;s the thing I mean if we&#39;re being honest everybody&#39;s fucking stealing from everybody it doesn&#39;t honestly it doesn&#39;t fucking matter</p>
<p>Dave! (01:41:31.37)
Yeah, this is what he&#39;s known for.</p>
<p>Dave! (01:41:37.43)
Yeah. I mean, are the house and house two scores that he did good? I think so. Are they memorable? No.</p>
<p>Bryan! (01:41:44.792)
Nah, nah. Yep, so they fight a bit more. We get.</p>
<p>Dave! (01:41:48.97)
Alice locks herself in the same, she locks herself in crazy Ralph&#39;s pantry.</p>
<p>Bryan! (01:41:53.176)
Yeah. And and yeah and so Mrs Voorhees like breaks through with the machete and she gets hit by Alice&#39;s like frying pan and this is the this is the oh you think the shape is dead scene.</p>
<p>Dave! (01:42:05.962)
Yeah, well she hits her with a frying pan and then she kicks her in the crotch.</p>
<p>Bryan! (01:42:11.216)
you gotta. You gotta. That&#39;s Oh, you can sympathize with it but like, of course, she comes</p>
<p>Dave! (01:42:12.242)
And man, that is rough. That is rough to see in a movie. Because, you know, you know it hurts. Man or woman, you know it hurts.</p>
<p>Bryan! (01:42:29.696)
And there&#39;s a lot of cat and mouse here and a sort of protracted ending until the two of them come face to face on the beach. And Alice ends up with Chekov&#39;s machete and she chops Mrs. Vorky&#39;s head off.</p>
<p>Dave! (01:42:39.238)
in what is probably one of the greatest horror moments of all time. The look on her face, that slow-mo look as she turns in her wide eyes as she really leans in.</p>
<p>Bryan! (01:42:43.978)
Oh, for sure.</p>
<p>Bryan! (01:42:49.04)
Uh huh. Yeah, of course. And again, like when we talk about the poster earlier, you&#39;re seeing this in your head right now as we talk about it. It&#39;s it&#39;s just that iconic.</p>
<p>Dave! (01:42:56.278)
Yep. I almost went with the, for the social media promos for this, I almost went with that image. Cause it&#39;s so fucking great.</p>
<p>Bryan! (01:43:05.024)
like, I&#39;m going to go to the yeah, also this and this is the sort of falls away and her hands</p>
<p>Dave! (01:43:13.758)
And our hands go up and I thought, I thought, well, my, what large hairy hands you have, Mrs. Voorhees.</p>
<p>Bryan! (01:43:21.205)
Yep, yep. Savini&#39;s hands right there. So that body shows up again the very same year in Maniac. Yep, at the at the at the end when</p>
<p>Dave! (01:43:29.262)
Wow. Oh, hey, I mean.</p>
<p>Penny saved is a penny earned. That&#39;s what Tom Savini always said.</p>
<p>Bryan! (01:43:36.904)
Oh shit. Yeah, like all those props he uses maniac show up in the prowler like, yeah, it&#39;s crazy. He&#39;s he&#39;s a man of economy. So Alice then pushes a canoe out into the lake and gets into it. And then in the</p>
<p>Dave! (01:43:48.45)
And then we get a lame, unnecessary ending, thanks to Brian De Palma.</p>
<p>Bryan! (01:43:51.448)
Yep. Again. So apparently Savini, they were like, oh, we this ending, we need a little something and Savini was like, hey, I just saw Carrie and so there you go.</p>
<p>Dave! (01:44:02.754)
Seriously, even the fucking score is Pinot Denaggio knockoff.</p>
<p>Bryan! (01:44:07.543)
It is, oh my god, because it&#39;s the, it&#39;s the dee dee. Yeah, like it&#39;s, yeah, it&#39;s so ready.</p>
<p>Dave! (01:44:11.39)
Yeah. And look, I am a huge fan of the Nasio fan, I can spot it every fucking time.</p>
<p>Bryan! (01:44:17.228)
Yep. So she&#39;s the only survivor and everything is all right, it would seem. But suddenly, a fucked up mutant Jason leaps up out of the lake and pulls her in.</p>
<p>Dave! (01:44:26.05)
I&#39;ll tell you what, I&#39;m watching this and I&#39;m thinking, you know what the shittiest part of all of this is? After all that&#39;s happened, Alice still has to row back to show.</p>
<p>Bryan! (01:44:35.388)
What a pain in the ass. Uh huh. So we, we cut to the hospital and she wakes up screaming. There was no boy. It was just her. We fade to a final shot of the lake.</p>
<p>Dave! (01:44:36.674)
God.</p>
<p>Dave! (01:44:45.166)
Yep, and she says, we never found another boy, and she says, then he&#39;s still there, waiting for another sequel or 10. And, Fiend.</p>
<p>Bryan! (01:44:50.571)
He&#39;s still there.</p>
<p>Bryan! (01:44:56.566)
Yeah. Yep. Roll credits. Friday the 13th, man.</p>
<p>Dave! (01:44:58.99)
Whew! That&#39;s a classic. I think that&#39;s the first heavy hitter that we&#39;ve done, like the first big one.</p>
<p>Bryan! (01:45:07.532)
Yeah, yeah, because this is definitely not really our forte. Like the main, like well-known titles is something that I think we kind of consciously avoid. Like we haven&#39;t done a Nightmare on Elm Street or.</p>
<p>Dave! (01:45:18.678)
Which is not to say I don&#39;t like it. I fucking love this series. I love all of it.</p>
<p>Bryan! (01:45:22.328)
Yeah, yeah, I mean, of all of the slasher franchises, this is definitely my favorite.</p>
<p>Dave! (01:45:27.402)
Halloween is my favorite, but this is a close second.</p>
<p>Bryan! (01:45:30.224)
Halloween as a movie is one of my favorite. I fucking hate the sequels.</p>
<p>Dave! (01:45:35.486)
I do not hate those sequels. I think they&#39;re fun. I don&#39;t think they&#39;re, I think that this series, this franchise learned very quickly what to do and how to have fun with it. It falls apart after the eighth one. Like I even think the eighth one is fucking great.</p>
<p>Bryan! (01:45:52.332)
Yeah, yeah. Like, like I have, like we did, we did our rankings a way back, a ways back, and it sort of sounds like when we get towards those last ones, they&#39;re the obvious, like last entries on my list for me, but even in that case, like that&#39;s not really a bad thing, like</p>
<p>Dave! (01:46:09.238)
No, because they knew how to have fun. They knew what to do with it. I think there are two, you can go two directions with it. You can either be Halloween and be a serious film that is very good, or you can go the other direction and have fun. But that&#39;s it. Those are your options.</p>
<p>Bryan! (01:46:12.462)
Sure.</p>
<p>Bryan! (01:46:24.088)
Oh yeah, like these all get more and more ridiculous as it goes on. And I love the way that the further sequels kind of roll out, because part two comes out a year after this, and I believe it&#39;s set like a year after this, but then part three chronologically picks up like the day after part two ends. And then I believe part four is also not, maybe not immediately after, but it&#39;s very close.</p>
<p>Dave! (01:46:38.07)
Yes.</p>
<p>Dave! (01:46:43.531)
Yes.</p>
<p>Dave! (01:46:49.118)
It&#39;s not immediate, but it&#39;s close.</p>
<p>Bryan! (01:46:52.172)
and then they kind of chop it off there and then</p>
<p>Dave! (01:46:54.178)
Five is about 10, 15 years later or something.</p>
<p>Bryan! (01:46:57.64)
something. Yeah, cuz Tommy Jarvis is like a fucking adult in that one. Yep. But yeah, like, but I do like the way that they&#39;re just like, alright, well, I guess if we&#39;re just gonna keep moving, making these movies, we&#39;re just gonna be stupid with it.</p>
<p>Dave! (01:47:00.514)
Yeah, it&#39;s like 10 years later.</p>
<p>Dave! (01:47:08.726)
Because the reality is, to be a good horror movie, there&#39;s so few and far between, and that&#39;s for a reason, because you gotta have a lot of fuckin&#39; talent to pull it off. John Carpenter has a lot of fuckin&#39; talent, that&#39;s why it worked. Sean Cunningham does not.</p>
<p>Bryan! (01:47:25.004)
Yeah. Because like honestly, this is probably the only real movie of note that he that he that he made. Yeah, I watched it just recently because that was one of those ones that came out at a time when they coincided with the release of I believe Leviathan, which was another monster. There&#39;s another one.</p>
<p>Dave! (01:47:32.26)
Yeah. I mean, Deep Star Sex is fucking garbage.</p>
<p>Dave! (01:47:43.274)
Yes, and there&#39;s another one too. Leviathan, that, and there&#39;s one more that I can&#39;t remember what it&#39;s called. What&#39;s the one that has Ed Harris in it? Because Peter Weller is in Leviathan.</p>
<p>Bryan! (01:47:52.748)
try. Yeah. Ed Harris will know Ed Harris is in the Cameron one, but that was a couple of years later. And that does not really compare at all. But I know that there is another there is another like monster deep underwater. It&#39;s basically the thing. Deep Star Six was the same thing Leviathan&#39;s the same thing. But yeah, it&#39;s</p>
<p>Dave! (01:48:05.739)
Novice sucks.</p>
<p>Bryan! (01:48:17.628)
I don&#39;t know, the moving into the late 80s, horror sort of starts to creep towards its natural ending. And we kind of go towards a kind of horror movie desert of the 90s. But.</p>
<p>Dave! (01:48:26.338)
But you gotta know where your limits are. And I feel like Friday the 13th, out of all of them, Friday the 13th really understood what its limits were. They were like, look, this is what we&#39;re good at. We&#39;re gonna do this until we can&#39;t do it anymore. Like, never in else you just drives it into the fucking ground until you hate it.</p>
<p>Bryan! (01:48:45.368)
I would say that yeah, Nightmare on Elm Street definitely like once you get to I actually really stopped giving a fuck about that one after part three.</p>
<p>Dave! (01:48:53.686)
Because they get progressively worse. Poor is OK. Then they get terrible until new nightmare.</p>
<p>Bryan! (01:48:56.476)
the first four is okay. Yeah. Uh there&#39;s like think of some of the others. Uh cuz it is really cuz like until about I don&#39;t say like the late 80s. The 1980s major slasher game is Freddie and Jason. You know, so yeah, big deal. I mean, it&#39;s definitely won&#39;t be the last of the sort of heavy hitters that we do but man, this the just a couple of a couple of weeks.</p>
<p>Dave! (01:49:21.174)
Well, no, just a couple of... Just down the road a piece.</p>
<p>Bryan! (01:49:26.564)
We got a good one coming up, but yeah. I don&#39;t know what else to say. It&#39;s it&#39;s so much fucking fun. Personally, I think the movies get better when Jason comes along, but this is this is a great movie. I love it to death, you know.</p>
<p>Dave! (01:49:41.334)
Yeah, my favorite is the third one, and I know it&#39;s a hot take, but I fucking love that one.</p>
<p>Bryan! (01:49:48.641)
Now my favorite is part two for sure. It&#39;s got the best final girl of all of them.</p>
<p>Dave! (01:49:53.398)
Yep, and hardcore lesbian.</p>
<p>Bryan! (01:49:57.556)
I did not know that. Thank.</p>
<p>Dave! (01:49:58.846)
I mean, I&#39;m making assumptions, but I&#39;m pretty sure.</p>
<p>Bryan! (01:50:03.588)
Yeah, I&#39;m gonna have to look it up. But yeah, Amy Steele is the shit. I love Jenny. The...</p>
<p>Dave! (01:50:09.034)
And I believe Amy Steele, isn&#39;t she also in Happy Birthday to Me?</p>
<p>Bryan! (01:50:12.368)
know she&#39;s in April Fool&#39;s Day. Yeah. The the twist of the year. Yep. So yeah, there it is Friday the 13th. So we&#39;re we&#39;ll see you we&#39;ll see you in a few days with another one when we visit Rob Zombie. I know Jesus fucking Christ. Yep. So yeah. Bye.</p>
<p>Dave! (01:50:14.464)
Ah, that&#39;s right. That one has a big tweeze.</p>
<p>But don&#39;t worry, there&#39;s a twist!</p>
<p>Dave! (01:50:24.757)
Bye.</p>
<p>Dave! (01:50:31.314)
Yeah, yikes.</p>
<p>Dave! (01:50:38.494)
Yep.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/halloween-2007">Next Episode</Link></li>
                        <li><Link to="/episodes/halloween-2018">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)